
const menuData = [
   
    {
      title: 'Menu',
      path: '/menu',
      
    },
    {
      title: 'Grievance Form',
      path: '/grievance-form',
    
    },
    {
      title: 'Update',
      path: '/update',
   
    },
    {
      title: 'Toll Free',
      path: '/toll-free',
     
    },
  ];
  
  export default menuData;
  