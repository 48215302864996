import React, { useEffect, useState } from "react";
//import './news.css';
import parse from "html-react-parser";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";

function parseTextEditor(text) {
    const lines = text.split("\n");
    const { i18n } = useTranslation();
    let result = [];

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i].trim();

        if (line.startsWith("-")) {
            result.push({ type: "bullet", content: line.substring(1).trim() });
        } else {
            result.push({ type: "text", content: line });
        }
    }

    return result;
}

function TextEditor({ text }) {
    const lines = parseTextEditor(text);

    return (
        <div>
            {lines.map((line, index) => {
                if (line.type === "bullet") {
                    return <li key={index}>{line.content}</li>;
                } else {
                    return <p key={index}>{line.content}</p>;
                }
            })}
        </div>
    );
}

const Body = ({ index }) => {
    console.log(index);
    const list = useSelector(getFaq);
    const { t, i18n } = useTranslation();
    const [item, setItem] = useState();
    const faqs = list[index];

    useEffect(() => {
        list.map((listItem) => {
            if (parseInt(listItem.number) === index) {
                console.log(listItem);
                setItem(listItem);
            }
        });
    }, []);

    return (
        <>
            {item && (
                <div className='flex flex-col  '>
                    <div className='flex flex-col items-center pt-20 pb-16 mb-10 text-3xl px-28 '>
                        <div className='flex w-full '>
                            <div className='w-[15%] flex items-start py-1 justify-center text-white bg-MetallicBlue'>
                                {" "}
                                {t("faq-answer")}
                            </div>

                            <div className='w-[85%] flex items-center pl-5  border-b-2  text-darkblue border-MetallicBlue'>
                                {" "}
                            </div>
                        </div>

                        <div className='self-start w-full mt-7'>
                            <p className='w-full text-4xl font-semibold text-MetallicBlue'>
                                {/* <TextEditor
                text={
                  i18next.language === "en"
                    ? faqs.answer
                    : i18next.language === "amh"
                    ? faqs.answer_amh
                    : ""
                }
              /> */}

                                <div>
                                    {i18n.language === "en"
                                        ? parse(item.answer)
                                        : parse(item.answer_amh)}
                                </div>
                            </p>
                        </div>
                    </div>

                    <div class='grid gap-10  grid-cols-3 mx-28  mb-28  '>
                        <div></div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Body;
