import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";
import parse from "html-react-parser";
import ExpandableContent from "react-expandable-content";
import { useSelector } from "react-redux";
import { getStrategies } from "../../../../redux/selectors";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const Content2 = () => {
    const list = useSelector(getStrategies);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const { t } = useTranslation();

    const sanitizeHTML = (html) => {
        // Configure DOMPurify to remove all styles
        return DOMPurify.sanitize(html, {
            ALLOWED_TAGS: ["b", "i", "em", "strong", "a"], // Add any tags you want to allow
            ALLOWED_ATTR: ["href"], // Add any attributes you want to allow
        });
    };

    const pdflinks = [
        {
            link: "https://drive.google.com/file/d/1po_EoOop-kZ6M-wTWHoXx85ZR3A_kqfv/view?usp=sharing",
        },
        {
            link: "https://drive.google.com/file/d/1g0zaO-WcOePTNgKk25LIuu248li0NL93/view?usp=sharing",
        },
        null,
        null,
        {
            link: "https://drive.google.com/file/d/16ZJ1AEUYyCTQfwILgX4xMnpvC_xh7D01/view?usp=sharing",
        },
        null,
    ];
    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='grid grid-cols-2 gap-2 mt-16 text-xl  md:mt-32'>
                    {/* <div className='relative col-span-2 px-5 text-justify md:px-40 bottom-10'>
                        <h2 className='px-5 text-4xl text-white bg-MetallicBlue w-fit bottom-5'>
                            {t("strategies-identity-proof")}
                        </h2>
                        <hr className='mb-10' />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t("strategies-identity-proof-text"),
                            }}
                        ></div>
                    </div> */}
                    <div className='relative col-span-2 px-5 text-justify md:px-40 bottom-10'>
                        <h2 className='px-5 text-4xl text-white bg-MetallicBlue w-fit bottom-5'>
                            Fayda ID Strategy
                        </h2>
                        <hr className='mb-10' />
                        <div>
                            Fayda is Ethiopia's foundational digital
                            identification (ID) system, designed to issue a
                            unique 12-digit identification number to all
                            eligible residents, ensuring "one person, one
                            identity" through biometric technology. This digital
                            ID aims to serve as a universal proof of identity,
                            crucial for accessing various services across the
                            country. The system is inclusive, targeting to
                            enroll 90 million residents by 2028 without
                            discrimination based on socio-economic status or
                            geographic location. Fayda's benefits are
                            multifaceted: it provides a valid legal identity,
                            allows biometric-based identification even if
                            credentials are lost, facilitates trusted
                            transactions, fosters interoperability, enhances
                            accountability, and transparency in economic and
                            social policies, and supports efficient service
                            delivery. Legally backed by the Ethiopian Digital
                            Identification
                            <Link
                                to='/law'
                                className='underline font-bold text-[#235D71]'
                            >
                                Proclamation No. 1284/2023
                            </Link>
                            , Fayda ensures personal data protection and
                            privacy, adhering to principles of minimal data
                            collection and purpose-driven data usage.
                        </div>
                        <div className='mt-5'>
                            <a
                                href={pdflinks[0].link}
                                target='_blank'
                                className='px-5 py-1.5 bg-MetallicBlue text-white rounded-md'
                            >
                                Read More
                            </a>
                        </div>
                    </div>

                    {list.map((listItem, index = 0) => (
                        <div
                            className={`${
                                index % 2 ? "" : "backdrop-brightness-95 "
                            } mb-16 text-justify col-span-2`}
                        >
                            <div className='grid w-full grid-cols-2 px-40 py-10'>
                                <div
                                    className={`flex justify-center items-center ${
                                        index % 2
                                            ? "col-start-1 row-start-1 px-5"
                                            : "col-start-2 row-start-1 ml-5"
                                    } ${
                                        index === 2 ? "" : ""
                                    } overflow-hidden `}
                                >
                                    <img
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                            listItem.image_url
                                        }
                                        alt='strategies'
                                        className={`${
                                            index % 2
                                                ? "object-right-top"
                                                : !index % 2
                                                ? "object-center"
                                                : ""
                                        } flex overflow-hidden object-cover px-2 max-h-[550px]`}
                                    />
                                </div>
                                <div
                                    className={`${
                                        index % 2
                                            ? "mx-7"
                                            : !index % 2
                                            ? "ml-7 mr-5"
                                            : ""
                                    } row-start-1 pb-5 py-16`}
                                >
                                    <div className='mb-3'>
                                        <h2 className='relative text-4xl font-semibold text-MetallicBlue'>
                                            <p>
                                                {i18next.language === "en"
                                                    ? parse(
                                                          sanitizeHTML(
                                                              listItem.title
                                                          )
                                                      )
                                                    : i18next.language === "amh"
                                                    ? parse(
                                                          sanitizeHTML(
                                                              listItem.title_amh
                                                          )
                                                      )
                                                    : ""}
                                            </p>
                                        </h2>
                                        <hr className='border-2 w-[18%] border-green' />
                                    </div>
                                    <div className='mb-5'>
                                        {i18next.language === "en"
                                            ? parse(sanitizeHTML(listItem.text))
                                            : parse(
                                                  sanitizeHTML(
                                                      listItem.text_amh
                                                  )
                                              )}
                                    </div>
                                    {pdflinks[index + 1] && (
                                        <a
                                            href={pdflinks[index + 1].link}
                                            target='_blank'
                                            className='px-5 py-1.5 bg-MetallicBlue text-white rounded-md'
                                        >
                                            Read More
                                        </a>
                                    )}
                                    {/* <ExpandableContent
                                        maxHeight={280}
                                        collapseText={"Read less"}
                                        expandText={"Read more"}
                                        className='bg-MetallicBlue text-white absolute left-0 hover:scale-105'
                                        showIcon={false}
                                    ></ExpandableContent> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {/************************* mobile view ************************ */}
                    <div className='grid grid-cols-2 gap-2 mt-16 text-xl  md:mt-32'>
                        {/* <div className='relative col-span-2 px-8 text-justify md:px-40 bottom-10'>
                            <h2 className='px-5 text-base text-white bg-MetallicBlue w-fit bottom-5'>
                                {t("strategies-identity-proof")}
                            </h2>
                            <hr className='mb-10' />
                            <div className='px-1 text-lg tracking-tight'>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "strategies-identity-proof-text"
                                        ),
                                    }}
                                ></div>
                            </div>
                        </div> */}

                        <div className='relative col-span-2 px-5 text-justify md:px-40 bottom-10'>
                            <h2 className='px-5 text-4xl text-white bg-MetallicBlue w-fit bottom-5'>
                                Fayda ID Strategy
                            </h2>
                            <hr className='mb-10' />
                            <div>
                                Fayda is Ethiopia's foundational digital
                                identification (ID) system, designed to issue a
                                unique 12-digit identification number to all
                                eligible residents, ensuring "one person, one
                                identity" through biometric technology. This
                                digital ID aims to serve as a universal proof of
                                identity, crucial for accessing various services
                                across the country. The system is inclusive,
                                targeting to enroll 90 million residents by 2028
                                without discrimination based on socio-economic
                                status or geographic location. Fayda's benefits
                                are multifaceted: it provides a valid legal
                                identity, allows biometric-based identification
                                even if credentials are lost, facilitates
                                trusted transactions, fosters interoperability,
                                enhances accountability, and transparency in
                                economic and social policies, and supports
                                efficient service delivery. Legally backed by
                                the Ethiopian Digital Identification
                                <Link
                                    to='/law'
                                    className='underline font-bold text-[#235D71]'
                                >
                                    Proclamation No. 1284/2023
                                </Link>
                                , Fayda ensures personal data protection and
                                privacy, adhering to principles of minimal data
                                collection and purpose-driven data usage.
                            </div>
                            <div className='mt-5'>
                                <a
                                    href={pdflinks[0].link}
                                    target='_blank'
                                    className='px-5 py-1.5 bg-MetallicBlue text-white rounded-md'
                                >
                                    Read More
                                </a>
                            </div>
                        </div>
                        {list.map((listItem, index = 0) => (
                            <div
                                className={`${
                                    index % 2 ? "" : "backdrop-brightness-95"
                                } mb-16 text-justify col-span-2`}
                            >
                                <div className='flex flex-col px-5'>
                                    <div className='w-[100%] flex justify-center items-center'>
                                        <img
                                            src={
                                                process.env.REACT_APP_BASE_URL +
                                                listItem.image_url
                                            }
                                            alt='strategies'
                                            className='flex overflow-hidden object-cover mb-5 max-h-[550px]'
                                        />
                                    </div>

                                    <div className='pb-10'>
                                        <div className='flex flex-col items-center justify-center mb-3'>
                                            <h2 className='flex justify-center text-xl font-semibold text-MetallicBlue'>
                                                <p>
                                                    {i18next.language ===
                                                        "en" &&
                                                        parse(
                                                            sanitizeHTML(
                                                                listItem.title
                                                            )
                                                        )}
                                                    {i18next.language ===
                                                        "amh" &&
                                                        parse(
                                                            sanitizeHTML(
                                                                listItem.title_amh
                                                            )
                                                        )}
                                                </p>
                                            </h2>
                                            <hr className='border-2 w-[18%] border-green' />
                                        </div>
                                        <p className='text-base'>
                                            <div className='mb-5'>
                                                {i18next.language === "en"
                                                    ? parse(
                                                          sanitizeHTML(
                                                              listItem.text
                                                          )
                                                      )
                                                    : parse(
                                                          sanitizeHTML(
                                                              listItem.text_amh
                                                          )
                                                      )}
                                            </div>
                                            {pdflinks[index + 1] && (
                                                <a
                                                    href={
                                                        pdflinks[index + 1].link
                                                    }
                                                    target='_blank'
                                                    className='px-5 py-1.5 bg-MetallicBlue text-white rounded-md'
                                                >
                                                    Read More
                                                </a>
                                            )}
                                            {/* <ExpandableContent
                                                maxHeight={278}
                                                collapseText={"Read less"}
                                                expandText={"Read more"}
                                                className='bg-MetallicBlue text-white absolute left-0 hover:scale-105'
                                                showIcon={false}
                                            >
                                                <div>
                                                    {i18next.language === "en"
                                                        ? parse(
                                                              sanitizeHTML(
                                                                  listItem.text
                                                              )
                                                          )
                                                        : parse(
                                                              sanitizeHTML(
                                                                  listItem.text_amh
                                                              )
                                                          )}
                                                </div>
                                            </ExpandableContent> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default Content2;
