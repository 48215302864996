import React, { useState } from "react";
import { Animate } from "../../../../hooks/animations";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useMediaQuery } from "react-responsive";
import faqIcon from "../../../../assets/6-01.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { getFaq } from "../../../../redux/selectors";
import parser from "react-html-parser";
const FaqTitle = () => {
    const list = useSelector(getFaq);
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    return (
        <>
            {isDesktopOrLaptop ? (
                <div className='flex flex-col items-center justify-center gap-10 p-6 pt-20 md:px-10 lg:px-20'>
                    <div className='flex flex-col items-center'>
                        <div className='w-full '>
                            <h1 className='mb-1 text-4xl font-semibold text-left md:text-5xl text-MetallicBlue '>
                                {t("faq-title")}
                            </h1>
                        </div>
                        <div className='relative w-full'>
                            <hr className='absolute w-full mb-10 border-gray-500 border-b-1' />
                            <hr className='absolute left-0 w-1/5 mb-10 border-b-4 md:w-1/4 border-green' />
                        </div>

                        <p className='my-6 text-xl font-normal text-left text-black md:text-2xl '>
                            {t("faq-desc")}
                        </p>

                        <div className='flex flex-col items-center self-center justify-center w-full gap-10 px-10 mt-4 md:flex-row md:gap-20'>
                            <a
                                // href='https://register.fayda.et/#/faq'
                                className='w-full md:w-auto'
                            >
                                <div
                                    className={`${
                                        activeButtonIndex === 1
                                            ? "text-MetallicBlue shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)]"
                                            : "bg-MetallicBlue text-green"
                                    } flex gap-6 md:gap-8  mt-10 place-items-center rounded-xl p-6 md:p-8 text-center hover:cursor-pointer scale-110 hover:scale-105 transition-all ease-out duration-300`}
                                    onClick={() => {
                                        setActiveButtonIndex(1);
                                    }}
                                >
                                    <div className='absolute left-0 flex flex-col items-start justify-start w-0 h-0 transition-all duration-300 ease-out group-hover:w-full group-hover:h-full bg-lightblue rounded-xl'></div>
                                    <Animate.FadeUp>
                                        <img
                                            src={faqIcon}
                                            alt='vision Icon'
                                            className='w-28 md:w-40 rounded-full border-white border-2 p-0.5'
                                        ></img>
                                    </Animate.FadeUp>
                                    <div className='flex flex-col'>
                                        <Animate.FadeUp>
                                            <h5 className='relative mb-2 text-xl font-bold text-left transition-all duration-300 ease-out cursor-pointer md:text-2xl '>
                                                {t("faq-card2")}
                                            </h5>
                                        </Animate.FadeUp>
                                        <Animate.FadeUp>
                                            <p className='relative text-base font-medium text-left text-white md:text-sm '>
                                                {t("faq-card-desc2")}
                                            </p>
                                        </Animate.FadeUp>
                                    </div>
                                </div>
                            </a>

                            <div className='bg-[#f6db9f] lg:w-[30%] h-full 2md:w-full w-full place-items-center rounded-xl  md:p-8 shadow-[5px_12px_50px_-14px_rgb(0,0,0,0.3)] b-'>
                                <Animate.FadeUp>
                                    <h5 className='p-2 mb-2 text-xl font-bold text-left md:text-2xl '>
                                        {t("faq-popular-questions")}
                                    </h5>
                                </Animate.FadeUp>
                                <Animate.FadeUp>
                                    <ul className='gap-4 place-items-center'>
                                        {list
                                            .slice(0, 4)
                                            .map((listItem, key) => (
                                                <a
                                                    href={`/faq${listItem.number}`}
                                                    key={key}
                                                >
                                                    <>
                                                        <li className='p-2 text-base font-light text-gray-500 md:text-lg  hover:text-lightblue/100'>
                                                            {i18next.language ===
                                                                "en" && (
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: listItem.question,
                                                                    }}
                                                                />
                                                            )}
                                                            {i18next.language ===
                                                                "amh" && (
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: listItem.question_amh,
                                                                    }}
                                                                />
                                                            )}
                                                        </li>
                                                        <hr className='' />
                                                    </>
                                                </a>
                                            ))}
                                    </ul>
                                </Animate.FadeUp>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                // Mobile Version
                <>
                    <div className='bg-[#f6db9f] text-MetallicBlue shadow-md rounded-md p-8 sm:p-8 md:p-12 lg:p-16'>
                        <h2 className='text-xl font-bold text-left md:text-2xl lg:text-4xl '>
                            {t("faq-popular-questions")}
                        </h2>
                        {list.slice(0, 4).map((listItem, key) => (
                            <div
                                key={key}
                                className='pt-4 border-b border-gray-200'
                            >
                                <a href={`/faq${listItem.number}`} key={key}>
                                    <h3 className='text-lg font-normal sm:text-xl md:text-2xl lg:text-3xl '>
                                        {i18next.language === "en" &&
                                            parser(listItem.question)}
                                        {i18next.language === "amh" &&
                                            parser(listItem.question_amh)}
                                    </h3>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className='flex items-center w-full h-full gap-4 px-5 align-middle bg-MetallicBlue'>
                        <div className='relative bg-white rounded-3xl  w-[60%] my-3'>
                            <input
                                className='block w-full py-1 pl-3 pr-10 text-base text-gray-500 placeholder-gray-400 border border-gray-300 appearance-none rounded-3xl placeholder:text-center focus:border-blue-500 focus:outline-none'
                                type='text'
                                placeholder='Search your questions'
                            />
                            <SearchIcon className='absolute flex items-center justify-center w-10 h-full text-gray-500 pointer-events-none top-2 right-2 opacity-40' />
                        </div>

                        <div className='flex w-[40%]'>
                            <a
                                // href='https://register.fayda.et/#/faq'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <button className='relative inline-block w-full py-1 font-bold text-white rounded-full '>
                                    Pre-Reg FAQs
                                </button>
                            </a>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default FaqTitle;
