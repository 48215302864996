import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { MdDoubleArrow, MdMenu, MdClose } from "react-icons/md";
import menuData from "./box";
import Form from "./Contents/Form/Form";
import Toll from "./Contents/Toll";
import Update from "./Contents/Update";
import { t } from "i18next";
import formbg from "../../../assets/help/seg.jpg";
import formBgGirl from "../../../assets/coms-black-female.jpg";
import formBgwriting from "../../../assets/girlWriting.jpg";
import GoogleCaptchaWrapper from "../../../components/GoogleCaptchaWrapper/GoogleCaptchaWrapper";

const FormWrapper = () => {
    return (
        <GoogleCaptchaWrapper>
            <Form />
        </GoogleCaptchaWrapper>
    );
};
const contentComponents = {
    "/grievance-form": FormWrapper,
    "/update": Update,
    "/toll-free": Toll,
};

const backgroundImages = {
    "/grievance-form": formbg,
    "/update": formBgwriting,
    "/toll-free": formBgGirl,
};

const Home = () => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState("/grievance-form");
    useEffect(() => {
        const isActiveItem = menuData.some(
            (item) => item.path === location.pathname
        );
        setActiveItem(isActiveItem ? location.pathname : "/grievance-form");
    }, []);

    const ActiveContent = contentComponents[activeItem] || null;

    return (
        <section
            className={`bg-no-repeat overflow-y-scroll scrollbar-hide ${
                activeItem === "/grievance-form" ? "bg-bottom" : "bg-top"
            } text-white min-h-screen font-barlow flex flex-col justify-center items-center text-center`}
            style={{
                backgroundImage: `url(${backgroundImages[activeItem]})`,
                backgroundSize: "cover",
            }}
        >
            <div className='absolute z-30 inset-0 bg-gradient-bottom-to-top'></div>
            <div className='w-full px-4 z-40 md:px-8 lg:px-0 flex flex-col items-center'>
                <div className='w-full max-w-[1000px] space-y-7'>
                    <div className='border border-white/20 bg-white/30 flex items-center justify-center rounded-md'>
                        <div className='hidden md:flex justify-center items-center gap-2 w-full bg-MetallicBlue text-2xl rounded-md lg:py-2 md:w-1/3 md:py-4'>
                            <div className='text-white h-8 px-4 hover:text-white flex items-center font-semibold'>
                                <MdDoubleArrow className='text-gray-500 mx-2 text-2xl font-light' />
                                Menu
                            </div>
                        </div>
                        <ul className='grid grid-cols-3 w-full p-2'>
                            {menuData
                                .filter((item) => item.title !== "Menu")
                                .map((item, index) => (
                                    <li
                                        key={index}
                                        className={`flex px-4 justify-center items-center w-full   ${
                                            index === 1 ? "border-x" : ""
                                        }`}
                                    >
                                        <button
                                            className={`text-white w-full h-8 px-4 hover:text-white flex items-center
                                                ${
                                                    activeItem === item.path
                                                        ? "border border-neutral-100 rounded bg-MetallicBlue/50"
                                                        : ""
                                                }
                                                ${
                                                    activeItem === item.path
                                                        ? "font-bold"
                                                        : ""
                                                }`}
                                            onClick={() =>
                                                setActiveItem(item.path)
                                            }
                                        >
                                            <span className='text-center w-full'>
                                                {item.title}
                                            </span>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>

                    {/* Content Area */}
                    <div
                        className='flex flex-col h-full md:flex-row rounded border bg-white/30 border-white/50 relative'
                        style={{ height: "37rem" }}
                    >
                        {/* Left Sidebar (Metallic Blue Content Area) */}
                        <div className='bg-MetallicBlue p-4 md:p-6 md:w-1/3 flex-shrink-0 flex flex-col gap-5 items-center justify-center h-full md:h-auto'>
                            <h2 className='text-5xl font-bold text-start text-white w-full'>
                                {t("help-title")}
                            </h2>
                            <h2 className='text-4xl w-full font-bold text-start text-green'>
                                {t("help-title2")}
                            </h2>
                            <p className='text-white text-2xl text-justify mb:mb-[14rem]'>
                                {t("left-help-description")}
                            </p>
                        </div>

                        {/* Right Sidebar (Main Content Area) */}
                        <div className='flex-1 p-4 md:p-6 flex flex-col items-center justify-center h-full'>
                            <ActiveContent />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
