import React from "react";
import mainLogo from "../../../../assets/check-mark.png";
import infoIcon from "../../../../assets/Info-icon.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useMediaQuery } from "react-responsive";

const identityProof = [
    {
        en: "Kebele ID",
        amh: "የቀበሌ መታወቂያ",
    },
    {
        en: "Passport",
        amh: "ፓስፖርት",
    },
    {
        en: "Birth Certificate",
        amh: "የልደት ሰርተፊኬት	",
    },
    {
        en: "Education Certificates(Diploma, Degree,Masters…)",
        amh: "የትምህርት ማስረጃ	",
    },
    {
        en: "Business License",
        amh: "የንግድ ምዝገባ ወይም የንግድ ፈቃድ ሰርተፊኬት	",
    },
    {
        en: "Marriage Certificate",
        amh: "የጋብቻ ሰርተፊኬት	",
    },
    {
        en: "Driver's license",
        amh: "መንጃ ፈቃድ	",
    },
    {
        en: "Bank Book",
        amh: "የባንክ አካውንት ደብተር	",
    },
    {
        en: "Vehicle Ownership License",
        amh: "የመኪና ሊብሬ",
    },
    {
        en: "House Title deed",
        amh: "የቤት ካርታ	",
    },
    {
        en: "Professional License",
        amh: " የሙያ ፈቃድ ማስረጃ	",
    },
    {
        en: "Taxpayer Identification Number/ID (ID Card with photo)",
        amh: "የግብር ከፋይነት መለያ ቁጥር/ መታወቂያ (ፎቶ ያለው)",
    },
    {
        en: "Election ID",
        amh: "የምርጫ ካርድ/መታወቂያ (የተመራጭነት/የመራጭነት ማስረጃ/መታወቂያ)",
    },
    {
        en: "Health Insurance Card",
        amh: "የጤና መድህን መታወቂያ",
    },
    {
        en: "Pension ID",
        amh: "የጡረታ መታወቂያ",
    },
    {
        en: "Idir ID Card",
        amh: " ዕድር ደብተር	",
    },
    {
        en: "Equb ID Card",
        amh: " የእቁብ ደብተር",
    },
    {
        en: "Adoption Center ID",
        amh: " የህፃናት ማሳደጊያ ተቋማት የሚሰጡ በፎቶ የተደገፈ ደብዳቤ",
    },
    {
        en: "Diplomat ID",
        amh: "የዲፕሎማት መታወቂያ(ኢትዮጵያ ውስጥ ነዋሪ መሆኑን የሚያመለክት)",
    },
    {
        en: "Diaspora ID",
        amh: " የዲያስፖራ ነዋሪነት ማረጋገጫ /Yellow card/ ( ኢትዮጵያ ውስጥ ነዋሪ የሆነ)",
    },
    {
        en: "Refugee ID (From RSS)",
        amh: "የስደተኛ ካርድ ወይም ከስደትና ስደት ተመላሽ አገልግሎት የሚሰጠው ማስረጃ",
    },
    {
        en: "Job seeker ID",
        amh: "የሥራ ፈላጊ መታወቂያ",
    },
    {
        en: "Student ID",
        amh: " የተማሪነት መታወቂያ",
    },
    {
        en: "Residence Permit",
        amh: "የነዋሪነት ፍቃድ (ለውጭ ሀገር ዜጎች)",
    },
    {
        en: "Work Permit ID (foreigner)",
        amh: "የሥራ ፈቃድ መታወቂያ ( ለውጭ ሃገር ዜጎች)",
    },
    {
        en: "International Organization Employment ID",
        amh: " የአለም አቀፍ ተቋማት የሰራተኛ መታወቂያ",
    },
    {
        en: "Any Government ID (With photo)",
        amh: "በማንኛውም የመንግሥት አካል የሚሰጥ መታወቂያ	",
    },
    {
        en: "Confirmation Paper given by Kebele Head with Photo",
        amh: " የቀበሌ ስራ አስኪያጅ የሚሰጠው ማረጋገጫ",
    },
    {
        en: "Finance Institutions ID",
        amh: "ፈቃድ ያላቸው ማንኛውም የፋይናንስ ተቋማት የሚሰጥ የማንነት ማስረጃ",
    },
];

const addressProof = [
    {
        en: "Court Letter ",
        amh: "ማንኛውም ከፍርድ ቤት የተሰጠ የማንነት ማስረጃ (ስም እና የትውልድ ቀን የሚያሳይ)",
    },
    {
        en: "Family Memebership Wittness",
        amh: " የቤተሰብ አባልነት ምስክርነት",
    },
    {
        en: "Community Memebership Witness",
        amh: " የማህበረሰብ አባልነትን ምስክርነት",
    },
];

const witnessProof = [
    {
        en: "Witnesses (Through another resident successfully registered in the Fayda System)",
        amh: "የሰው ምስክር (የፋይዳ ስርዓቱ ላይ የተመዘገበ)	",
    },
];

const Poi = () => {
    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    return (
        <>
            {isDesktopOrLaptop ? (
                <div class='text-darkblue mt-0  w-full  bg-grey'>
                    <div className='flex flex-col w-full'>
                        <div className='flex items-center pt-20 pb-16 mb-10 text-3xl bg-white px-28 '>
                            <div className='w-[20%] flex items-center justify-center text-white bg-MetallicBlue'>
                                {" "}
                                {t("proof-poi-identity")}
                            </div>
                            <div className='w-[70%] flex items-center pl-5  border-b-2  text-darkblue border-MetallicBlue'>
                                {" "}
                                {t("proof-poi-identity-text")}
                            </div>
                        </div>

                        <div className='grid grid-cols-3 mx-28   h-[70%] place-items-center align-middle  gap-8 mb-16'>
                            {identityProof.map((id, index) => (
                                <div className='flex items-start justify-start w-full p-3 text-2xl'>
                                    <img
                                        src={mainLogo}
                                        className='h-7'
                                        alt='fireSpot'
                                    />
                                    <p className='px-3'>
                                        {i18next.language === "en" && id.en}{" "}
                                        {i18next.language === "amh" && id.amh}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex items-center w-full h-full  text-5xl bg-[#f6db9f] px-28 py-6'>
                        <img
                            src={infoIcon}
                            className='h-10 pr-7'
                            alt='fireSpot'
                        />

                        <h1 className='pr-2 '>{t("proof-poi-for")} </h1>
                        <h1 className='font-bold '>
                            {" "}
                            {t("proof-poi-acceptance")}
                        </h1>

                        <div className='w-0.5 h-16 bg-black/20 mx-10'></div>

                        <div className='flex flex-col w-full'>
                            <p className='w-full h-full text-2xl '>
                                {t("proof-poi-acceptance-text1")}{" "}
                            </p>
                            <p className='w-full h-full text-2xl'>
                                {" "}
                                {t("proof-poi-acceptance-text2")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='flex pt-20 pb-20 mb-10 text-3xl bg-white px-28 '>
                        <div className='w-[20%] flex items-center justify-center text-white bg-MetallicBlue'>
                            {" "}
                            {t("proof-poi-address")}
                        </div>
                        <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                            {" "}
                            {t("proof-poi-address-text")}
                        </div>
                    </div>
                    <div className='grid grid-cols-3 mx-28   h-[70%] place-items-center align-middle  gap-8 mb-16'>
                        {addressProof.map((address, index) => (
                            <div className='flex items-start justify-start w-full text-2xl'>
                                <img
                                    src={mainLogo}
                                    className='h-7'
                                    alt='fireSpot'
                                />
                                <p className='px-3'>
                                    {i18next.language === "en" && address.en}{" "}
                                    {i18next.language === "amh" && address.amh}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='flex items-center w-full h-full  text-5xl bg-[#f6db9f] px-28 py-6'>
                        <img
                            src={infoIcon}
                            className='h-10 pr-7'
                            alt='fireSpot'
                        />

                        <h1 className='pr-2 '>{t("proof-poi-for")} </h1>
                        <h1 className='font-bold '>
                            {t("proof-poi-acceptance")}
                        </h1>

                        <div className='w-0.5 h-16 bg-black/20 mx-10'></div>

                        <div className='flex flex-col w-full'>
                            <p className='w-full h-full text-2xl '>
                                {t("proof-poi-acceptable-text3")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='flex pt-20 pb-20 mb-10 text-3xl bg-white px-28  '>
                        <div className='w-[20%] flex items-center justify-center text-white bg-MetallicBlue'>
                            {" "}
                            {t("proof-poi-witness")}
                        </div>

                        <div className='w-[70%] flex items-center pl-5 border-b-2  text-darkblue border-MetallicBlue'></div>

                        <div></div>
                    </div>

                    <div className='px-28 text-MetallicBlue'>
                        <p className='text-xl pb-'>
                            {" "}
                            {t("proof-poi-witness-text1")}{" "}
                        </p>

                        <p className='text-xl'>
                            {" "}
                            {t("proof-poi-witness-text2")}{" "}
                        </p>

                        <div className='pb-28 pl-28 '>
                            {witnessProof.map((witness, index) => (
                                <div className='flex '>
                                    <img
                                        src={mainLogo}
                                        className='pr-4 h-7'
                                        alt='fireSpot'
                                    />
                                    <p className='text-xl'>
                                        {" "}
                                        {i18next.language === "en" &&
                                            witness.en}{" "}
                                        {i18next.language === "amh" &&
                                            witness.amh}{" "}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='w-full h-20 bg-white'></div>
                </div>
            ) : (
                <div class='text-darkblue w-full bg-grey'>
                    <div className='flex flex-col w-full'>
                        <div className='flex px-5 pt-10 pb-8 text-xl bg-white '>
                            <div className='w-[100%] flex items-center justify-center text-white bg-MetallicBlue'>
                                {" "}
                                {t("proof-poi-identity")}
                            </div>
                            <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                                {" "}
                            </div>
                        </div>

                        <div className='grid grid-cols-2 h-[70%]  gap-5 gap-x-5 mb-8'>
                            {identityProof.map((id, index) => (
                                <div className='flex items-start justify-start w-full p-3 text-lg'>
                                    <img
                                        src={mainLogo}
                                        className='h-5'
                                        alt='fireSpot'
                                    />
                                    <p className=''>
                                        {i18next.language === "en" && id.en}{" "}
                                        {i18next.language === "amh" && id.amh}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className='h-10 bg-white'></div>
                    </div>

                    <div className='flex flex-col items-center w-full text-3xl bg-[#f6db9f] py-3'>
                        <div className='flex'>
                            <img
                                src={infoIcon}
                                className='h-8 pr-3'
                                alt='fireSpot'
                            />

                            <h1 className='pr-2 '>{t("proof-poi-for")} </h1>
                            <h1 className='font-bold '>
                                {" "}
                                {t("proof-poi-acceptance")}
                            </h1>

                            <div className='w-0.5 h-10 bg-black/20 ml-1'></div>
                        </div>

                        <div className='flex flex-col w-full px-4'>
                            <p className='w-full h-full text-lg tracking-tighter text-justify'>
                                {t("proof-poi-acceptance-text1")}{" "}
                                {t("proof-poi-acceptance-text2")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='flex items-center px-5 py-5 mb-5 text-2xl bg-white '>
                        <div className='w-[100%] flex items-center justify-center text-white bg-MetallicBlue'>
                            {" "}
                            {t("proof-poi-address")}
                        </div>
                        <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'>
                            {" "}
                            {t("proof-poi-address-text")}
                        </div>
                    </div>
                    <div className='grid grid-cols-2 h-[70%]  gap-5 gap-x-10 mb-8'>
                        {addressProof.map((address, index) => (
                            <div className='flex items-start justify-start w-full pl-5 text-lg'>
                                <img
                                    src={mainLogo}
                                    className='h-7'
                                    alt='fireSpot'
                                />
                                <p className=''>
                                    {i18next.language === "en" && address.en}{" "}
                                    {i18next.language === "amh" && address.amh}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-col items-center w-full h-full  text-3xl bg-[#f6db9f]  py-6'>
                        <div className='flex'>
                            <img
                                src={infoIcon}
                                className='h-8 pr-3'
                                alt='fireSpot'
                            />

                            <h1 className='pr-2 '>{t("proof-poi-for")} </h1>
                            <h1 className='font-bold '>
                                {" "}
                                {t("proof-poi-acceptance")}
                            </h1>

                            <div className='w-0.5 h-10 bg-black/20 ml-1'></div>
                        </div>

                        <div className='flex flex-col w-full px-4'>
                            <p className='w-full h-full text-lg tracking-tighter text-justify'>
                                {t("proof-poi-acceptable-text3")}{" "}
                            </p>
                        </div>
                    </div>

                    <div className='flex items-center px-5 py-5 mb-5 text-2xl bg-white '>
                        <div className='w-[100%] flex items-center justify-center text-white bg-MetallicBlue'>
                            {" "}
                            {t("proof-poi-witness")}
                        </div>
                        <div className='w-[70%] flex items-center pl-5 border-b-2 text-darkblue border-MetallicBlue'></div>
                    </div>

                    <div className='px-5 text-MetallicBlue'>
                        <p className='text-lg leading-snug tracking-tight text-justify'>
                            {" "}
                            {t("proof-poi-witness-text1")}{" "}
                        </p>
                        <br />
                        <p className='text-lg leading-snug tracking-tight text-justify'>
                            {" "}
                            {t("proof-poi-witness-text2")}{" "}
                        </p>
                        <p className='pb-3 text-xl'>shall be:-</p>

                        <div className='pb-10 mb-10 '>
                            {witnessProof.map((witness, index) => (
                                <div className='flex items-start justify-start w-full pl-5 text-lg'>
                                    <img
                                        src={mainLogo}
                                        className='pr-4 h-7'
                                        alt='fireSpot'
                                    />
                                    <p className='text-xl tracking-tight text-justify'>
                                        {" "}
                                        {i18next.language === "en" &&
                                            witness.en}{" "}
                                        {i18next.language === "amh" &&
                                            witness.amh}{" "}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Poi;
