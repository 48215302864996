import AACityAdminstration from "../../../assets/partners/AACityAdminstration.png";
import BoA from "../../../assets/partners/BoA.png";
import awashbank from "../../../assets/partners/awashbank.png";
import cbe from "../../../assets/partners/cbe.png";
import dashen from "../../../assets/partners/dashen.jpg";
import documentAuthentication from "../../../assets/partners/documentAuthentication.png";
import enat from "../../../assets/partners/enat.png";
import ethiopost from "../../../assets/partners/ethiopost.png";
import Nib from "../../../assets/partners/Nib.png";
import oromia from "../../../assets/partners/oromia.png";
import oromiaBaankii from "../../../assets/partners/oromiaBaankii.png";
import hibretbank from "../../../assets/partners/hibretbank.png";
import nbe from "../../../assets/partners/NBE.png";
import mircoFinance from "../../../assets/partners/microfinance.png";
import ethiotele from "../../../assets/partners/ethiotele.png";

export const partnersSmallConfig = [
    {
        title: "ethio telecom",
        locations: 52,
        image: ethiotele,
    },
    {
        title: "Ministry of Revenue",
        locations: 141,
        image: AACityAdminstration,
    },

    {
        title: "Documents Authentication And Registration Service",
        locations: 14,
        image: documentAuthentication,
    },

    {
        title: "Ethiopost",
        locations: 1,
        image: ethiopost,
    },

    {
        title: "National Bank of Ethiopia",
        locations: 64,
        image: nbe,
    },
];
export const partnersLargeConfig = [
    {
        title: "ethio telecom",
        locations: 29,
        image: ethiotele,
    },
    {
        title: "Ministry of Revenue",
        locations: 25,
        image: AACityAdminstration,
    },
    {
        title: "Micro Finance(private sectors)",
        locations: 16,
        image: mircoFinance,
    },
    {
        title: "Documents Authentication And Registration Service",
        locations: 14,
        image: documentAuthentication,
    },

    {
        title: "Ethiopost",
        locations: 1,
        image: ethiopost,
    },

    {
        title: "Hibret Bank",
        locations: 3,
        image: hibretbank,
    },
    {
        title: "Nib International Bank",
        locations: 8,
        image: Nib,
    },
    {
        title: "Bank of Oromia",
        locations: 6,
        image: oromia,
    },
    {
        title: "Oromia Bank",
        locations: 3,
        image: oromiaBaankii,
    },
    {
        title: "Awash Bank",
        locations: 8,
        image: awashbank,
    },
    {
        title: "Bank of Abyssinia",
        locations: 5,
        image: BoA,
    },
    {
        title: "Commercial Bank of Ethiopia",
        locations: 15,
        image: cbe,
    },
    {
        title: "Dashen Bank",
        locations: 8,
        image: dashen,
    },
    {
        title: "Enat Bank",
        locations: 8,
        image: enat,
    },
];
