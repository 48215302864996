import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import logo from "../../assets/logo_white.png";
import ButtonDropDown from "../dropdown/buttonDropDown";

import "./navbar.css";

import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useTranslation } from "react-i18next";

const dummyData = [
    {
        id: 1,
        name: "Home",
        path: "/",
    },
    {
        id: 2,
        name: "About Us",
        path: "/about",
    },
    {
        id: 3,
        name: "Services",
        path: "/services",
        subMenu: [
            {
                id: 1,
                name: "Credentials",
                path: "/credentials",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 2,
                name: "Enrollment",
                path: "/enrollment",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 4,
                name: "authentication",
                path: "/authentication",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 3,
                name: "card-print",
                path: "/cardprint",
                view: "stakeholder",
                type: "internal",
            },
        ],
    },
    {
        id: 4,
        name: "Updates",
        path: "/news",

        subMenu: [
            {
                id: 1,
                name: "News",
                path: "/news",
                view: "stakeholder",
                type: "internal",
            },

            {
                id: 2,
                name: "Events",
                path: "/events",
                view: "stakeholder",
                type: "internal",
            },

            {
                id: 3,
                name: "Media",
                path: "/media",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 4,
                name: "Vacancies",
                path: "/career",
                view: "citizen",
                type: "internal",
            },
            {
                id: 5,
                name: "Podcast",
                path: "/podcast",
                view: "citizen",
                type: "internal",
            },
            {
                id: 6,
                name: "Tender",
                path: "/tender",
                view: "citizen",
                type: "internal",
            },
        ],
    },
    {
        id: 5,
        name: "FAQ",
        path: "/faq",
    },
    {
        id: 6,
        name: "Resources",
        path: "/resources",

        subMenu: [
            {
                id: 1,
                name: "MOU",
                path: "/mou",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 2,
                name: "Decks",
                path: "/decks",
                view: "stakeholder",
                type: "internal",
            },

            {
                id: 3,
                name: "Policies and Strategies",
                path: "/policies",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 8,
                name: "Manuals",
                path: "/manuals",
                view: "law",
                type: "internal",
            },
            {
                id: 4,
                name: "Publications",
                path: "/documents",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 5,
                name: "Specifications",
                path: "/nidspecifications",
                view: "stakeholder",
                type: "internal",
            },
            {
                id: 6,
                name: "Law",
                path: "/law",
                view: "law",
                type: "internal",
            },
            {
                id: 7,
                name: "world-bank",
                path: "/worldbank",
                view: "law",
                type: "internal",
            },
        ],
    },
    {
        id: 7,
        name: "Contact Us",
        path: "/contact",
    },
];

const TopBar = ({ viewState }) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    const [index, setIndex] = useState(0);
    const { t } = useTranslation();
    const scrollPosition = useScrollPosition();

    const [active, setActive] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    const handleRedirectLink = (linkRedirect) => {
        console.log("linkRedirect", linkRedirect);
        window.open(linkRedirect, "_self");
    };
    return (
        <Navbar
            className={classNames(
                scrollPosition > 0 ? "bg-MetallicBlue" : "transparent",
                "fixed w-full flex justify-between items-center pb-1 z-50 transition-shadow "
            )}
        >
            <Navbar.Brand className='absolute left-0 flex gap-2 '>
                <div class='flex p-5 mt-3 '>
                    <img
                        src={logo}
                        alt='National Id logo'
                        className='left-0 h-16 w-23 md:object-scale-down md:h-18 hover:cursor-pointer'
                        onClick={() => navigate("/")}
                    ></img>
                    {/* <button className="px-6 py-1 mt-5 ml-56 text-base font-medium transition duration-150 ease-in-out shadow-lg rounded-2xl dropdown-toggle bg-green/30 whitespace-nowrap"> Language</button> */}

                    <ButtonDropDown />
                </div>
            </Navbar.Brand>

            <Container fluid>
                <Navbar.Toggle />
                <Navbar.Collapse id='navbar-dark-example'>
                    <Nav className='items-center w-full mt-3 mr-10'>
                        {dummyData.map((itemData, index) => (
                            <NavLink
                                key={index}
                                to={`${itemData.path}`}
                                className={(navData) =>
                                    !navData.isActive
                                        ? "px-3 ml-2 no-underline"
                                        : "px-3 ml-2 underline decoration-[#00f7c7] decoration-4 decoration-offset-4 hover:-translate-y-1 hover:scale-110 hover:cursor-pointer  transition duration-200 "
                                }
                            >
                                {itemData.subMenu ? (
                                    <div class='dropdown inline-block relative '>
                                        <button class=' text-white font-semibold pb-4 px-2 rounded inline-flex items-center '>
                                            <span
                                                class={`mr-1 text-base font-semibold hover:scale-110 transition duration-200 ${
                                                    active === itemData.name
                                                        ? "underline decoration-[#00f7c7]  decoration-4"
                                                        : "no-underline"
                                                } `}
                                            >
                                                {t(itemData.name)}
                                            </span>
                                            <svg
                                                class='fill-current h-4 w-4'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 20 20'
                                            >
                                                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />{" "}
                                            </svg>
                                        </button>
                                        <ul class='dropdown-menu absolute hidden text-white '>
                                            {itemData.subMenu.map(
                                                (subItem, index) => (
                                                    <li
                                                        class=''
                                                        key={index * 100}
                                                    >
                                                        <span class=' bg-darkblue/70 hover:bg-green/80 px-7 py-1 font-semibold hover:text-MetallicBlue rounded-sm border-white/20 border-b-2 block whitespace-no-wrap  hover:scale-110'>
                                                            {subItem.type ===
                                                            "internal" ? (
                                                                <Link
                                                                    to={
                                                                        subItem.path
                                                                    }
                                                                    onClick={() => {
                                                                        setActive(
                                                                            itemData.name
                                                                        );
                                                                    }}
                                                                >
                                                                    {t(
                                                                        subItem.name
                                                                    )}
                                                                </Link>
                                                            ) : (
                                                                <div
                                                                    onClick={() =>
                                                                        handleRedirectLink(
                                                                            `https://` +
                                                                                subItem.path
                                                                        )
                                                                    }
                                                                >
                                                                    {" "}
                                                                    {t(
                                                                        subItem.name
                                                                    )}
                                                                </div>
                                                            )}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                            {/* <li class=""><a class="rounded-t bg-green/30 hover:bg-green/70 py-2 px-4 block whitespace-no-wrap" href="#">One</a></li>
                        <li class=""><a class="bg-green/30 hover:bg-green/70 py-2 px-4 block whitespace-no-wrap" href="#">Two</a></li>
                        <li class=""><a class="rounded-b bg-green/30 hover:bg-green/70 py-2 px-4 block whitespace-no-wrap" href="#">Three is the magic number</a></li> */}
                                        </ul>
                                    </div>
                                ) : (
                                    <span className='text-base font-semibold text-white hover:scale-110'>
                                        <Link
                                            to={`${itemData.path}`}
                                            className='nav-link hover:scale-125'
                                        >
                                            {t(itemData.name)}
                                        </Link>
                                    </span>
                                )}
                            </NavLink>
                        ))}
                        {/* <SearchIcon className="text-white text-md ml-10 rounded-2xl border-white border-2 p-0.5 hover:cursor-pointer hover:scale-110 transition duration-200"/> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
export default TopBar;
