import React from "react";
import { useTranslation } from "react-i18next";

const Update = () => {
    const {t}=useTranslation()
    return (
        <div className='p-4'>
            <div className='flex flex-col items-center justify-center gap-5 h-fit'>
                <div className='flex flex-col text-center gap-5'>
                    <h1 className='text-3xl md:text-4xl font-semibold text-MetallicBlue'>
                        {t("services-update-no")}{" "}
                    </h1>
                    <p className='font-semibold text-black/60 text-xl text-justify'>
                        {t("help-update-description")}
                    </p>
                </div>
                <a href='tel:9779'>
                    <p className='text-xl text-white px-16 bg-green hover:bg-[#083247] rounded-md py-2'>
                        {t("call-now")}{" "}
                    </p>
                </a>
            </div>
        </div>
    );
};

export default Update;