import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import mainLogo from "../../../../assets/check-mark.png";
import ReactPaginate from "react-paginate";

const Body = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const { t } = useTranslation();
    const data = [
        {
            id: 1,
            title: {
                en: " Basic proof of identity",
                am: "የማንነት መሰረታዊ ማስረጃ",
            },
            description: {
                en: `
        <div>
<p><span style="font-weight: 400;">Basic proof of identity is a simple way to establish your identity or verify your personal information. Fadya ID enables one to verify their identity in a reliable and secure manner by integrating their demographic information with their physical biometrics. Fayda ID provides a secure and verifiable form of identification, to access services at both federal and regional levels. It facilitates accessing key government and private sector services, such as:&nbsp;</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Opening a bank account</span></li>
</ul>
<p><span style="font-weight: 400;">It enables people to have access to banking services and participate in the formal economy, empowering them to save, transact, and build a stronger financial foundation.</span></p>
<p></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Having a medical ID</span></li>
</ul>
<p><span style="font-weight: 400;">It will ensure prompt and accurate medical care, providing vital information in emergencies and enhancing patient safety.</span></p>
<p></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Having a student ID</span></li>
</ul>
<p><span style="font-weight: 400;">&nbsp;It gives students access to various educational resources, Including library privileges, discounts, and other facilities.</span></p>
</div>`,
                am: `
        <div>
        <p><span style="font-weight: 400;">የፋይዳ ማንነትን&nbsp; የሚያረጋግጥ መሠረታዊ መታወቂያ&nbsp; ነው:: ፋይዳ አንድ ሰው የሕዝብ ነክ (Demographic) መረጃን ከባዮሜትሪክ (Biometrics) መረጃ ጋር በማዋሃድ ማንነትን አስተማማኝ በሆነ መንገድ ለማረጋገጥ ያስችላቸዋል። የፋይዳ መታወቂያ በፌዴራልም ሆነ በክልል ደረጃ ቁልፍ የመንግስት አገልግሎቶችን ለማግኘት አስተማማኝ እና ሊረጋገጥ የሚችል መታወቂያ ይሰጣል። ለምሳሌ -</span></p>
        <p></p>
        <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">የባንክ ሂሳብ መክፈት</span></li>
        </ul>
        <p><span style="font-weight: 400;">የባንክ አገልግሎቶችን ለማግኘት እና በመደበኛ ኢኮኖሚ ውስጥ ለመሳተፍ ይረዳል።</span></p>
        <p></p>
        <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">የህክምና መታወቂያ ማግኘት</span></li>
        </ul>
        <p><span style="font-weight: 400;">አፋጣኝና ትክክለኛ የሕክምና ክትትል ማድረግ፣ ድንገተኛ አደጋ ሲከሰት ጠቃሚ መረጃ መስጠትና የታካሚውን ደህንነት ማሻሻል ያስፈልጋል።ለዚህም ፋይዳ መሠረታዊ መታወቂያ በመሆን ያገለግላል።&nbsp;</span></p>
        <p></p>
        <ul>
        <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">የተማሪ መታወቂያ ማግኘት</span></li>
        </ul>
        <p><span style="font-weight: 400;">ተማሪዎች እንደ ቤተ-መፅሀፍት አገልግሎት ያሉ&nbsp; የትምህርት አገልግሎቶችን እንዲያገኙ ያገለግላል። </span></p>
        </div>`,
            },
        },
        {
            id: 2,

            title: {
                en: "Getting TIN number",
                am: "የቲን ቁጥር ማግኘት",
            },
            description: {
                en: `
        <p><span style="font-weight: 400;">A Taxpayer Identification Number (TIN), is a unique identification number used by the government to provide service to individuals or businesses for taxation and reporting purposes. To obtain a TIN number, having a Fadya ID is a requirement to verify your identity before going to the next step. This will also prevent identity fraud and facilitate digitized services.</span></p>
        `,
                am: `<p><span style="font-weight: 400;">የታክስ ከፋይ መለያ ቁጥር (ቲን) ለግለሰቦች ወይም ለንግድ ድርጅቶች አገልግሎት ለመስጠት የሚጠቀሙበት ልዩ የመታወቂያ ቁጥር ነው። የቲን ቁጥርን ለማግኘት፣ የፋይዳ መለያ ማግኘት ቅድመ ሁኔታ ከመሆኑ በተጨማሪ የማንነት ማጭበርበርን በመከላከል ዲጂታላይዝድ አገልግሎቶችን ያቀላጥፋል::</span></p>`,
            },
        },
        {
            id: 3,

            title: {
                en: "For Document Authentication and Registration Service (DARs)",
                am: "ለሰነድ ማረጋገጫ እና ምዝገባ አገልግሎት (DARs)",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Proving one&rsquo;s&nbsp; identity is required to process power of attorney, to sign company resolutions, to sign agreements including rental agreements and sale agreements at the Document Authentication and Registration Service (DARs). Fayda ID is a legal proof of identity acceptable at the DARs for these purposes.</span></p>
       `,
                am: `<p><span style="font-weight: 400;">ፋይዳ የተለያዩ አገልግሎቶችን በሰነድ ማረጋገጫ እና ምዝገባ አገልግሎት ለማግኘት ጥቅም ላይ ሊውል ይችላል። በተለይም ሰነዶችን ለማረጋገጥ፣&nbsp; ስምምነቶችን ለመፈረም፣ ውክልና ለመስጠት፣ መመስረቻ ሰነድ ለመፈረም&nbsp; ያገለግላል።</span></p>`,
            },
        },
        {
            id: 4,

            title: {
                en: "Business Incorporation and Related Services",
                am: "የንግድ ድርጅት ለማቋቋም እና ተዛማጅ አገልግሎቶች ለማግኘት",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Business incorporation requires proving one's identity at different stages. Company or business name approval, signing articles of association, issuance of commercial registration and business license require proving one's identity. Fayda ID can be used to prove one's identity before trade bureaus to facilitate business incorporation. </span></p>
       `,
                am: `<p><span style="font-weight: 400;">የንግድ ድርጅት ለማቋቋም&nbsp; የአንድን ሰው ማንነት ማረጋገጥ ይጠይቃል። ኩባንያ ወይም የንግድ ስም ፈቃድ ለማግኘት፣ የድርጅት ቃለ-ጉባኤ ላይ ለመፈረም፣ የንግድ ምዝገባ እና የንግድ ፈቃድ ማውጣት የአንድን ሰው ማንነት ማረጋገጥ ያስፈልጋል። የፋይዳ መታወቂያ በንግድ ቢሮዎች እነዚህ አገልግሎቶች ለማግነት ያገለግላል ።</span></p>`,
            },
        },
        {
            id: 5,

            title: {
                en: "Identity Verification Before Law Enforcement and Judicial Organs",
                am: " የፍትህ አካላት",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Police officers, prison administrations, and courts require proof of identity for various services including to appear as witnesses, to file lawsuit or to submit response to lawsuits among others. Fayda ID will ensure your identity is verified before law enforcement and judicial organs.</span></p>  `,
                am: `<p><span style="font-weight: 400;">የፖሊስ አካላት፣ የማረሚያ ቤት አስተዳደሮችና ፍርድ ቤቶች የተለያዩ አገልግሎቶችን ለመስጠት ማለትም ምስክር ሆኖ ለመቅረብ፣ ክስ ለማቅረብ፣ የክስ ምላሽ ለማቅረብን ጨምሮ የተለያዩ አገልግሎቶችን ለማግኘት መታወቂያ ያስፈልጋል። የፋይዳ መታወቂያ እነዚህን አገልግሎቶች ለማግኘት ያስፈልጋል። </span></p>`,
            },
        },
        {
            id: 6,

            title: {
                en: "Convenient Governmental and Non-Governmental Services",
                am: "የመንግስት እና መንግስታዊ ያልሆኑ አገልግሎቶችን ማሣለጥ",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Fayda ID provides a secure and verifiable form of identification, to access services at both federal and regional levels. It facilitates accessing key government services, such as obtaining a passport or driver&rsquo;s license. </span></p>`,
                am: `<p><span style="font-weight: 400;">የፋይዳ መታወቂያ በፌደራል እና በክልል ደረጃ የተለያዩ አገልግሎቶችን ለማግኘት ደህንነቱ የተጠበቀ መታወቂያ ነው። ፋይዳ የተለያዩ የመንግስት አገልግሎቶችን ማለትም&nbsp; ፓስፖርት ወይም የመንጃ ፍቃድ ለማግንኘት ያገለግላል።</span></p>`,
            },
        },
        {
            id: 7,

            title: {
                en: "Improved security",
                am: "የተሻሻለ ደህንነት",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Fayda ID can help prevent fraudulent acts such as ghost accounts by providing a secure and standardized form of identification due to &ldquo;one person, one identity&rdquo; principle. This can prevent corrupt practices and double dipping and ensures social protection programs such as Productive Safety Net Program are efficiently implemented while addressing the needs of the rightful beneficiaries.</span></p>`,
                am: `<p><span style="font-weight: 400;">የፋይዳ መታወቂያ በ"አንድ ሰው አንድ ማንነት" መርህ ላይ የተመሠረት በመሆኑ ደህንነቱ የተጠበቀ እና ደረጃውን የጠበቀ የመታወቂያ&nbsp; በማቅረብ የማጭበርበር ድርጊቶችን ለመከላከል ይረዳል። ይህም&nbsp; በማህበራዊ ጥበቃ ፕሮግራም ትግበራ ውስጥ ያሉ ብልሹ አሰራሮችን እና ያልተገባ ጥቅም ማግኘትን ይከላከላል ፤ ልማታዊ ሴፍቴይ ኔት ፕሮግራም ያሉ የማህበራዊ ጥበቃ መርሃ ግብሮችን ትክክለኛ የተጠቃሚዎችን ችግር በሚፈታበት መንገድ መተግበሩን ያረጋግጣል።</span></p>`,
            },
        },
        {
            id: 8,

            title: {
                en: "Improved Voting and Civic Participation",
                am: "የተሻሻለ የምርጫ ሂደት እና የሲቪክ ተሳትፎ",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Fayda ID can help to improve the accuracy of voting rolls and increase residents ' ability to participate in the political process including preventing election fraud contributing to democratic outcome. </span></p>`,
                am: `<p><span style="font-weight: 400;">የፋይዳ መታወቂያ የድምጽ መስጫ ወረቀቶችን ትክክለኛነት ለማሻሻል እና የነዋሪዎችን የፖለቲካ&nbsp;</span><span style="font-weight: 400;">ተሳትፎ ለመጨመር እና የምርጫ ማጭበርበርን በመቀነስ ለዴሞክራሲያዊ ውጤት አስተዋጽኦ ያበረክታል።</span></p>`,
            },
        },
        {
            id: 9,

            title: {
                en: "Improved Financial Inclusion",
                am: "የተሻሻለ የፋይናንስ ማካተት",
            },
            description: {
                en: `<p><span style="font-weight: 400;">By providing a standard form of identification, a Fayda&nbsp; ID can help residents access to improved financial services such as opening bank account,&nbsp; access loans, and insurance services. In addition, Fayda ensures the establishment of e-KYC thereby enhancing de-risking the financial sector. </span></p>`,
                am: `<p><span style="font-weight: 400;">የመደበኛ መታወቂያ የፋይዳ መታወቂያ ነዋሪዎች የተሻሻሉ የፋይናንስ አገልግሎቶችን ለምሳሌ የባንክ ሂሳብ&nbsp; መክፈት፣ ብድር እና የኢንሹራንስ አገልግሎቶችን እንዲያገኙ ይረዳል። በተጨማሪም ፋይዳ ኤሌክትሮኒክ ደንበኛህን እወቅ (e-KYC) በማደራጀት የፋይናንስ ሴክተሩን ከፋይናንስ አደጋ ይጠብቃል።</span></p>`,
            },
        },
        {
            id: 10,

            title: {
                en: "Improved statistics and record keeping ",
                am: "የተሻሻለ ስታቲስቲክስ እና መዝገብ አያያዝ",
            },
            description: {
                en: `<p><span style="font-weight: 400;">Fayda ID can help the government keep accurate records of its residents, which can be useful for planning (strategy, budget etc..),&nbsp; policy development and decision-making purposes.</span></p>`,
                am: `<p><span style="font-weight: 400;">የፋይዳ መታወቂያ መንግስት የነዋሪዎቹን ትክክለኛ መዛግብት እንዲይዝ ሊረዳው ይችላል ይህም ለዕቅድ (ስትራቴጂ፣ በጀት ወዘተ..)፣ ለፖሊሲ ልማት እና ውሳኔ ለመስጠት ጠቃሚ ይሆናል።</span></p>`,
            },
        },
    ];
    const [currentPage, setCurrentPage] = useState(0);
    let itemsPerPage;
    if (isDesktopOrLaptop) {
        itemsPerPage = 3;
    } else {
        itemsPerPage = 2;
    }

    function handlePageClick({ selected }) {
        setCurrentPage(selected);
        window.scrollTo(0, 0);
    }

    const offset = currentPage * itemsPerPage;

    const pageCount = Math.ceil(data.length / itemsPerPage);
    const paginatedItems = data.slice(offset, offset + itemsPerPage);

    return (
        <>
            {isDesktopOrLaptop ? (
                <>
                    <div className='mb-10'>
                        <div className='flex gap-6 text-xl  mt-10'>
                            <div className='px-5 w-full text-justify md:px-28 bottom-10 text-MetallicBlue'>
                                <div className='flex text-MetallicBlue w-[100%]'>
                                    <h2 className='text-5xl font-semibold bottom-5 mr-2'>
                                        {t("benefits-body-title")}
                                    </h2>
                                    <h2 className='text-5xl bottom-5 font-light'>
                                        {t("benefits-body-title2")}
                                    </h2>
                                </div>

                                <div className='flex flex-col  '>
                                    <div className='flex w-full h-full mt-2'>
                                        <hr className='border-green h-[20%] bg-green w-[10%]' />
                                        <hr className='w-[85%] border-1' />
                                    </div>
                                    <div>
                                        {t("benefits-identity-description")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-6 mt-10 text-xl  text-MetallicBlue'>
                            {paginatedItems.map((item) => (
                                <div className='col-span-2 px-5 text-justify md:px-40 bottom-10 backdrop-brightness-95 py-10'>
                                    <h2 className='px-5 text-2xl text-white bg-MetallicBlue w-[25%] bottom-5'></h2>

                                    <div>
                                        <div className='flex'>
                                            <img
                                                src={mainLogo}
                                                className='h-4 mr-2 mt-4'
                                                alt='fireSpot'
                                            />
                                            <h2 className='text-3xl  font-bold'>
                                                {i18next.language === "en" &&
                                                    item.title.en}{" "}
                                                {i18next.language === "amh" &&
                                                    item.title.am}
                                            </h2>
                                        </div>
                                        <p className='py-5'>
                                            {item.id == 1 ? (
                                                i18next.language === "en" ? (
                                                    <div className='py-5'>
                                                        <div>
                                                            <p className='font-normal'>
                                                                Basic proof of
                                                                identity is a
                                                                simple way to
                                                                establish your
                                                                identity or
                                                                verify your
                                                                personal
                                                                information.
                                                                Fadya ID enables
                                                                one to verify
                                                                their identity
                                                                in a reliable
                                                                and secure
                                                                manner by
                                                                integrating
                                                                their
                                                                demographic
                                                                information with
                                                                their physical
                                                                biometrics.
                                                                Fayda ID
                                                                provides a
                                                                secure and
                                                                verifiable form
                                                                of
                                                                identification,
                                                                to access
                                                                services at both
                                                                federal and
                                                                regional levels.
                                                                It facilitates
                                                                accessing key
                                                                government and
                                                                private sector
                                                                services, such
                                                                as:
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-bold'>
                                                                    Opening a
                                                                    bank account
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                It enables
                                                                people to have
                                                                access to
                                                                banking services
                                                                and participate
                                                                in the formal
                                                                economy,
                                                                empowering them
                                                                to save,
                                                                transact, and
                                                                build a stronger
                                                                financial
                                                                foundation.
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-bold'>
                                                                    Having a
                                                                    medical ID
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                It will ensure
                                                                prompt and
                                                                accurate medical
                                                                care, providing
                                                                vital
                                                                information in
                                                                emergencies and
                                                                enhancing
                                                                patient safety.
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-bold'>
                                                                    Having a
                                                                    student ID
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                It gives
                                                                students access
                                                                to various
                                                                educational
                                                                resources,
                                                                including
                                                                library
                                                                privileges,
                                                                discounts, and
                                                                other
                                                                facilities.
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='py-5'>
                                                        <div>
                                                            <p className='font-normal'>
                                                                የፋይዳ ማንነትን&nbsp;
                                                                የሚያረጋግጥ መሠረታዊ
                                                                መታወቂያ&nbsp; ነው::
                                                                ፋይዳ አንድ ሰው የሕዝብ
                                                                ነክ (Demographic)
                                                                መረጃን ከባዮሜትሪክ
                                                                (Biometrics) መረጃ
                                                                ጋር በማዋሃድ ማንነትን
                                                                አስተማማኝ በሆነ መንገድ
                                                                ለማረጋገጥ ያስችላቸዋል።
                                                                የፋይዳ መታወቂያ
                                                                በፌዴራልም ሆነ በክልል
                                                                ደረጃ ቁልፍ የመንግስት
                                                                አገልግሎቶችን ለማግኘት
                                                                አስተማማኝ እና ሊረጋገጥ
                                                                የሚችል መታወቂያ ይሰጣል።
                                                                ለምሳሌ -
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-normal'>
                                                                    የባንክ ሂሳብ
                                                                    መክፈት
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                የባንክ አገልግሎቶችን
                                                                ለማግኘት እና በመደበኛ
                                                                ኢኮኖሚ ውስጥ ለመሳተፍ
                                                                ይረዳል።
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-normal'>
                                                                    የህክምና መታወቂያ
                                                                    ማግኘት
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                አፋጣኝና ትክክለኛ
                                                                የሕክምና ክትትል ማድረግ፣
                                                                ድንገተኛ አደጋ ሲከሰት
                                                                ጠቃሚ መረጃ መስጠትና
                                                                የታካሚውን ደህንነት
                                                                ማሻሻል ያስፈልጋል።ለዚህም
                                                                ፋይዳ መሠረታዊ መታወቂያ
                                                                በመሆን
                                                                ያገለግላል።&nbsp;
                                                            </p>
                                                            <ul className='list-disc pl-20'>
                                                                <li className='font-normal'>
                                                                    የተማሪ መታወቂያ
                                                                    ማግኘት
                                                                </li>
                                                            </ul>
                                                            <p className='font-normal ml-20'>
                                                                ተማሪዎች እንደ
                                                                ቤተ-መፅሀፍት አገልግሎት
                                                                ያሉ&nbsp; የትምህርት
                                                                አገልግሎቶችን እንዲያገኙ
                                                                ያገለግላል።
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            i18next.language ===
                                                            "en"
                                                                ? item
                                                                      .description
                                                                      .en
                                                                : i18next.language ===
                                                                  "amh"
                                                                ? item
                                                                      .description
                                                                      .am
                                                                : "",
                                                    }}
                                                />
                                            )}
                                        </p>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="col-span-2 px-5 text-justify md:px-40 bottom-10 backdrop-brightness-95 py-10">
              <h2 className="px-5 text-2xl text-white bg-MetallicBlue w-[25%] bottom-5">
               
              </h2>
             
              <div>
                <div className="flex">
                  <img src={mainLogo} className="h-4 mr-2 mt-4" alt="fireSpot" />
                  <h2 className="text-3xl w-[35%] mb-5 font-bold">
                    {t("benefits-body-label2")}
                  </h2>
                </div>
                <p className="tracking-tight">
                  {t("benefits-identity-description2")}
                </p>
              </div>
            </div> */}

                            {/* <div className="flex ml-40 my-10 gap-10 hover:border-MetallicBlue">
              <button className="border-2 rounded-md px-12 hover:bg-green">
                Previous{" "}
              </button>
              <button className="border-2 rounded-md px-16 hover:bg-green">
                {" "}
                Next{" "}
              </button>
            </div> */}
                        </div>
                    </div>
                    <div className='gap-6 px-5 font-medium'>
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            previousClassName='border border-opacity-40 border-MetallicBlue rounded-md hover:bg-MetallicBlue hover:text-green mr-5 px-2 py-1'
                            nextClassName='border border-opacity-40 border-MetallicBlue rounded-md hover:bg-MetallicBlue hover:text-green px-2 py-1'
                            pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 hidden'
                            containerClassName={
                                "pagination flex justify-center text-MetallicBlue mt-5 gap-1 mb-14 hover:cursor-pointer"
                            }
                            activeClassName={"active bg-green px-5"}
                            activeLinkClassName='active-link'
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='mb-10'>
                        <div className='flex text-xl  mt-10'></div>
                        <div className='flex flex-col gap-6 mt-5 text-xl  text-MetallicBlue'>
                            {paginatedItems.map((item) => (
                                <div className='px-5 text-justify backdrop-brightness-95 py-5'>
                                    <h2 className='text-2xl text-white bg-MetallicBlue w-[80%]'>
                                        {i18next.language === "en" &&
                                            item.title.en}{" "}
                                        {i18next.language === "amh" &&
                                            item.title.am}
                                    </h2>
                                    <hr className='mb-5' />
                                    <p className='py-5'>
                                        {item.id == 1 ? (
                                            i18next.language === "en" ? (
                                                <div class='py-5'>
                                                    <div>
                                                        <p class='font-normal'>
                                                            Basic proof of
                                                            identity is a simple
                                                            way to establish
                                                            your identity or
                                                            verify your personal
                                                            information. Fadya
                                                            ID enables one to
                                                            verify their
                                                            identity in a
                                                            reliable and secure
                                                            manner by
                                                            integrating their
                                                            demographic
                                                            information with
                                                            their physical
                                                            biometrics. Fayda ID
                                                            provides a secure
                                                            and verifiable form
                                                            of identification,
                                                            to access services
                                                            at both federal and
                                                            regional levels. It
                                                            facilitates
                                                            accessing key
                                                            government and
                                                            private sector
                                                            services, such as:
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-bold'>
                                                                Opening a bank
                                                                account
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            It enables people to
                                                            have access to
                                                            banking services and
                                                            participate in the
                                                            formal economy,
                                                            empowering them to
                                                            save, transact, and
                                                            build a stronger
                                                            financial
                                                            foundation.
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-bold'>
                                                                Having a medical
                                                                ID
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            It will ensure
                                                            prompt and accurate
                                                            medical care,
                                                            providing vital
                                                            information in
                                                            emergencies and
                                                            enhancing patient
                                                            safety.
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-bold'>
                                                                Having a student
                                                                ID
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            It gives students
                                                            access to various
                                                            educational
                                                            resources, including
                                                            library privileges,
                                                            discounts, and other
                                                            facilities.
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div class='py-5'>
                                                    <div>
                                                        <p class='font-normal'>
                                                            የፋይዳ ማንነትን&nbsp;
                                                            የሚያረጋግጥ መሠረታዊ
                                                            መታወቂያ&nbsp; ነው:: ፋይዳ
                                                            አንድ ሰው የሕዝብ ነክ
                                                            (Demographic) መረጃን
                                                            ከባዮሜትሪክ (Biometrics)
                                                            መረጃ ጋር በማዋሃድ ማንነትን
                                                            አስተማማኝ በሆነ መንገድ
                                                            ለማረጋገጥ ያስችላቸዋል። የፋይዳ
                                                            መታወቂያ በፌዴራልም ሆነ በክልል
                                                            ደረጃ ቁልፍ የመንግስት
                                                            አገልግሎቶችን ለማግኘት
                                                            አስተማማኝ እና ሊረጋገጥ የሚችል
                                                            መታወቂያ ይሰጣል። ለምሳሌ -
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-normal'>
                                                                የባንክ ሂሳብ መክፈት
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            የባንክ አገልግሎቶችን ለማግኘት
                                                            እና በመደበኛ ኢኮኖሚ ውስጥ
                                                            ለመሳተፍ ይረዳል።
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-normal'>
                                                                የህክምና መታወቂያ ማግኘት
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            አፋጣኝና ትክክለኛ የሕክምና
                                                            ክትትል ማድረግ፣ ድንገተኛ አደጋ
                                                            ሲከሰት ጠቃሚ መረጃ መስጠትና
                                                            የታካሚውን ደህንነት ማሻሻል
                                                            ያስፈልጋል።ለዚህም ፋይዳ
                                                            መሠረታዊ መታወቂያ በመሆን
                                                            ያገለግላል።&nbsp;
                                                        </p>
                                                        <ul class='list-disc pl-20'>
                                                            <li class='font-normal'>
                                                                የተማሪ መታወቂያ ማግኘት
                                                            </li>
                                                        </ul>
                                                        <p class='font-normal'>
                                                            ተማሪዎች እንደ ቤተ-መፅሀፍት
                                                            አገልግሎት ያሉ&nbsp;
                                                            የትምህርት አገልግሎቶችን
                                                            እንዲያገኙ ያገለግላል።
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18next.language ===
                                                        "en"
                                                            ? item.description
                                                                  .en
                                                            : i18next.language ===
                                                              "amh"
                                                            ? item.description
                                                                  .am
                                                            : "",
                                                }}
                                            />
                                        )}
                                    </p>
                                </div>
                            ))}
                            {/* <div className="px-5 text-justify backdrop-brightness-95 py-5">
              <h2 className="text-2xl text-white bg-MetallicBlue w-[80%]">
                {t("benefits-body-label2")}
              </h2>
              <hr className="mb-5" />
              <div className="text-xl leading-tight tracking-tight">
                {t("benefits-identity-description2")}
              </div>
            </div> */}

                            {/* <div className="flex self-center gap-10 hover:border-MetallicBlue my-4">
              <button className="border-2 rounded-md px-12 hover:bg-green">
                Previous{" "}
              </button>
              <button className="border-2 rounded-md px-16 hover:bg-green">
                {" "}
                Next{" "}
              </button>
            </div> */}
                        </div>
                    </div>
                    <div className='gap-6 px-2 font-medium'>
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            previousClassName='border border-opacity-20 rounded-sm hover:bg-green mr-10 px-3 py-1'
                            nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                            pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 hidden'
                            containerClassName={
                                "pagination flex justify-center text-MetallicBlue mt-10 gap-1"
                            }
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default Body;
