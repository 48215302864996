import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import TextTruncate from "react-text-truncate";
import i18next from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactPaginate from "react-paginate";
import Iframe from "react-iframe";
import ProcPdf from "../../../wp-pdfiles/procPdf";
import { useSelector } from "react-redux";
import { getResources } from "../../../../redux/selectors";
import parse from "html-react-parser";
import { useDebounce } from "../../../../hooks/useDebounce";
const menuItems = [
    {
        name: "resource-all",
        pathname: "/resources",
    },
    {
        name: "resource-mou",
        pathname: "/mou",
    },
    {
        name: "resource-deck",
        pathname: "/decks",
    },
    {
        name: "resource-polices",
        pathname: "/policies",
    },
    {
        name: "resource-manuals",
        pathname: "/manuals",
    },
    {
        name: "resource-publications",
        pathname: "/documents",
    },
    {
        name: "resource-specifications",
        pathname: "/nidspecifications",
    },
    {
        name: "resource-law",
        pathname: "/law",
    },
];

const Sidebar = ({ title, citizen }) => {
    const list = useSelector(getResources);
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState("");
    const navigate = useNavigate();
    const subPath = useLocation();
    const { t } = useTranslation();
    const debounced = useDebounce(searchParams); // used to delay search until user is finished typing
    const handleNavigate = (num, path) => {
        setActiveButtonIndex(num);
        navigate(path);
        setOpen(!open);
    };

    console.log("list", list);
    const FilteredList = list.filter(
        (item) =>
            item.title.toLowerCase().includes(debounced.toLowerCase()) ||
            (item.title_amh.toLowerCase().includes(debounced.toLowerCase()) &&
                item)
    );
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const [currentPage, setCurrentPage] = useState(0);
    let itemsPerPage;
    if (isDesktopOrLaptop) {
        itemsPerPage = 4;
    } else {
        itemsPerPage = 8;
    }

    function handlePageClick({ selected }) {
        setCurrentPage(selected);
    }

    const offset = currentPage * itemsPerPage;
    const sortedData = FilteredList.filter((listItem) => {
        return title === "All" || title === listItem.category;
    }).sort((a, b) => {
        // your custom sorting logic goes here
    });
    const pageCount = Math.ceil(sortedData.length / itemsPerPage);
    const paginatedItems = sortedData.slice(offset, offset + itemsPerPage);

    return (
        <>
            {list.length > 0 ? (
                <>
                    {isDesktopOrLaptop ? (
                        <div className='flex flex-col justify-center bg-black/5 bg-lightblue'>
                            <div className='flex items-center justify-center h-20 gap-10  bg-[#7bacbd] place-items-center align-middle '>
                                {/* <div className="bg-white w-[30%] rounded-2xl py-1.5 flex text-lg justify-center  h-9 items-center ">Search Documents</div>
            <div className="h-10 bg-green"></div> */}
                                <div className='flex items-center w-[30%] align-middle h-full '>
                                    <input
                                        value={searchParams}
                                        onChange={(e) =>
                                            setSearchParams(e.target.value)
                                        }
                                        className='block w-full py-2 pl-8 pr-48 text-lg text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-center'
                                        placeholder={t("resource-search-text")}
                                    />
                                    <SearchIcon className='-ml-9' />
                                </div>

                                <div className='text-xl font-bold text-white  '>
                                    <button
                                        className={`content-center px-6 hover:cursor-pointer hover:underline focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                                    >
                                        {t("resource-filter-latest")}
                                    </button>
                                    <button
                                        className={`content-center px-6 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                                    >
                                        {t("resoruce-filter-name")}
                                    </button>
                                </div>

                                <div></div>
                            </div>

                            <div className='flex justify-start h-full px-32 pt-16 bg-grey '>
                                <div className='w-[20%] mt-10 mb-20 flex flex-col j  text-MetallicBlue  '>
                                    <h2 className='flex w-full ml-3 text-2xl font-bold text-left '>
                                        {t("resource-filter-categories")}
                                    </h2>

                                    <div className='grid gap-8 mt-4 text-xl font-semibold grid-rows-7  '>
                                        {menuItems.map((menuItem, index) => (
                                            <div
                                                className={`${
                                                    subPath.pathname ===
                                                    menuItem.pathname
                                                        ? "flex bg-green rounded-lg"
                                                        : ""
                                                } flex justify-start  items-center  p-2 hover:cursor-pointer`}
                                                onClick={() =>
                                                    handleNavigate(
                                                        1,
                                                        menuItem.pathname
                                                    )
                                                }
                                            >
                                                <h3 className='self-start ml-2 '>
                                                    {" "}
                                                    {t(menuItem.name)}
                                                </h3>
                                                {subPath.pathname ===
                                                    menuItem.pathname && (
                                                    <div class='ml-auto '>
                                                        {/* <svg class="w-5 h-5 text-green-400" stroke="currentColor" viewBox="0 0 24 24" ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg> */}
                                                        <FaChevronRight className='w-4 h-4 text-white' />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='flex items-start justify-start px-10 '>
                                    <div className='justify-center flex  w-0.5 h-[95%] ml-1 bg-lightblue '></div>
                                </div>

                                <div className='w-full mb-10 bg- '>
                                    <div className='grid w-[100%] px-5  py-5 grid-cols-2  gap-x-10 mt-5 mb-4 text-center   h-[800px] '>
                                        {paginatedItems.reduce(
                                            (acc, listItem, key) => {
                                                acc.push(
                                                    <div
                                                        onClick={(e) => {
                                                            if (
                                                                listItem.document
                                                            ) {
                                                                if (
                                                                    listItem.document
                                                                        .toLowerCase()
                                                                        .startsWith(
                                                                            "https"
                                                                        )
                                                                ) {
                                                                    window.open(
                                                                        listItem.document,
                                                                        "_blank"
                                                                    );
                                                                } else {
                                                                    window.open(
                                                                        process
                                                                            .env
                                                                            .REACT_APP_BASE_URL +
                                                                            listItem.document,
                                                                        "_blank"
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        className='w-full '
                                                        // onClick={(e) => {
                                                        //   if (listItem.document) {
                                                        //     if (
                                                        //       listItem.document
                                                        //         .toLowerCase()
                                                        //         .startsWith("https")
                                                        //     ) {
                                                        //       window.open(listItem.document, "_blank");
                                                        //     } else {
                                                        //       window.open(
                                                        //         process.env.REACT_APP_BASE_URL +
                                                        //           listItem.document,
                                                        //         "_blank"
                                                        //       );
                                                        //     }
                                                        //   }
                                                        // }}
                                                    >
                                                        <div className='transition-all duration-200  hover:scale-105 h-[400px] pt-6  flex flex-col'>
                                                            <div
                                                                className={`${
                                                                    listItem.category ===
                                                                        "MoU" ||
                                                                    listItem.category ===
                                                                        "Specifications"
                                                                        ? "items-center justify-center flex object-cover bg-white   px-[15%] h-[300px] overflow-clip  "
                                                                        : "items-center justify-center flex object-cover bg-white    h-[400px] overflow-clip "
                                                                }`}
                                                            >
                                                                {listItem.category ===
                                                                "MoU" ? (
                                                                    <img
                                                                        className=''
                                                                        src={
                                                                            process
                                                                                .env
                                                                                .REACT_APP_BASE_URL +
                                                                            listItem.image
                                                                            //   listItem.category === "MoU"
                                                                            //     ?  process.env.REACT_APP_BASE_URL + listItem.image
                                                                            //     : resourceImg
                                                                            // }
                                                                            // className={`${
                                                                            //   listItem.category === "MoU"
                                                                            //     ? "flex object-contain w-full h-full rounded-lg"
                                                                            //     : "flex object-contain w-full h-full rounded-lg"
                                                                            // }`
                                                                        }
                                                                        alt='resource_title'
                                                                    ></img>
                                                                ) : (
                                                                    <iframe
                                                                        title={
                                                                            listItem.title
                                                                        }
                                                                        src={
                                                                            listItem.document
                                                                        }
                                                                        width='100%'
                                                                        height='100%'
                                                                        frameBorder='0'
                                                                    />
                                                                )}

                                                                {/* <Iframe
                                url="https://docs.google.com/document/d/1PQCYaBWyrHbJztNtfv6-ICuf8WGShrdjRy_frYl8psk/edit?usp=sharing"
                                id=""
                                className="flex w-[100%] -z-20"
                                display="block"
                                position="relative"
                              /> */}
                                                            </div>
                                                            {/* RESOURCE FILE TITLE AND DESCRIPTION */}
                                                            <div className='flex flex-col w-full gap-0 overflow-hidden rounded-b-lg shadow-lg bg-grey '>
                                                                <div className='flex justify-between items-center px-3 pt-3 h-[40px]'>
                                                                    <h1
                                                                        className='text-base font-semibold'
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                i18next.language ===
                                                                                "en"
                                                                                    ? listItem.title
                                                                                    : listItem.title_amh,
                                                                        }}
                                                                    />

                                                                    {listItem.category !==
                                                                        "MoU" && (
                                                                        <button
                                                                            className='h-10 min-w-fit w-40 text-md text-white px-10 bg-green rounded-2xl hover:bg-[#083247]'
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                if (
                                                                                    listItem.document
                                                                                ) {
                                                                                    if (
                                                                                        listItem.document
                                                                                            .toLowerCase()
                                                                                            .startsWith(
                                                                                                "https"
                                                                                            )
                                                                                    ) {
                                                                                        window.open(
                                                                                            listItem.document,
                                                                                            "_blank"
                                                                                        );
                                                                                    } else {
                                                                                        window.open(
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_BASE_URL +
                                                                                                listItem.document,
                                                                                            "_blank"
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                "read-more"
                                                                            )}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                <div className='flex h-[30px] py-1 rounded-b-lg overflow-clip justify-center bg-'>
                                                                    <p
                                                                        className={` px-3 ${
                                                                            listItem.category ===
                                                                            "Specifications"
                                                                                ? "cursor-pointer text-lightblue underline"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    i18next.language ===
                                                                                    "en"
                                                                                        ? listItem.description
                                                                                        : i18next.language ===
                                                                                          "amh"
                                                                                        ? listItem.description_amh
                                                                                        : "x",
                                                                            }}
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );

                                                return acc;
                                            },
                                            []
                                        )}
                                    </div>

                                    <div className='w-full gap-6 '>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            previousClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                            nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                            pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                            containerClassName={
                                                "pagination flex justify-center text-MetallicBlue mt-10  gap-3"
                                            }
                                            activeClassName={"active bg-green"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        //*****************************************************Mobile Version *****************************************************

                        <div className='flex flex-col justify-center bg-black/5'>
                            <div className='flex flex-col items-center justify-center gap-0  bg-[#7bacbd] place-items-center align-middle'>
                                <div className='flex items-center w-full h-full gap-2 px-5 align-middle'>
                                    <div className='flex items-center bg-white rounded-3xl  w-[60%] my-3'>
                                        <input
                                            value={searchParams}
                                            onChange={(e) =>
                                                setSearchParams(e.target.value)
                                            }
                                            className='block w-full py-1 pl-3 text-base text-gray-500 placeholder-gray-400 appearance-none rounded-3xl placeholder:text-center'
                                            placeholder='Search Documents'
                                        />
                                        <SearchIcon className='' />
                                    </div>

                                    <div className='flex w-[40%]'>
                                        <div className='relative inline-block'>
                                            <input
                                                onClick={() => setOpen(!open)}
                                                className='block w-full py-1 pr-10 text-base text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-center hover:cursor-pointer'
                                                placeholder='Categories'
                                                readOnly
                                            />
                                            <svg
                                                className='absolute top-0 right-0 w-10 h-full text-gray-400 pointer-events-none'
                                                viewBox='0 0 20 20'
                                                fill='currentColor'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M10 12a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z'
                                                    clipRule='evenodd'
                                                />
                                            </svg>
                                            {open ? (
                                                <div className='absolute z-10 px-2 py-2 mt-2 -ml-1 bg-white rounded-lg shadow-lg'>
                                                    <div className='z-10 w-full overflow-y-scroll max-h-60'>
                                                        {menuItems.map(
                                                            (menuItem, key) => (
                                                                <div
                                                                    className='px-3 py-2 cursor-pointer hover:bg-gray-100'
                                                                    onClick={() =>
                                                                        handleNavigate(
                                                                            1,
                                                                            menuItem.pathname
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        menuItem.name
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full text-base font-bold  text-darkblue  bg-[#c7c7c7] py-2  pl-5'>
                                    <button
                                        className={`content-center px-6 hover:cursor-pointer hover:underline focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                                    >
                                        {t("resource-filter-latest")}
                                    </button>
                                    <button
                                        className={`content-center px-6 hover:cursor-pointer hover:underline  focus:underline focus:decoration-[#00f7c7] decoration-4 decoration-offset-10 `}
                                    >
                                        {t("resoruce-filter-name")}
                                    </button>
                                </div>

                                <div></div>
                            </div>

                            <div className='flex flex-col justify-start px-5 py-10 flex-2 grid-cols-0'>
                                <div className='grid grid-cols-2 grid-rows-4 gap-y-5 gap-x-6 '>
                                    {paginatedItems.reduce(
                                        (acc, listItem, key) => {
                                            acc.push(
                                                <div
                                                    className='flex flex-col w-full mb-0 cursor-pointer h-[240px]'
                                                    onClick={(e) => {
                                                        if (listItem.document) {
                                                            if (
                                                                listItem.document
                                                                    .toLowerCase()
                                                                    .startsWith(
                                                                        "https"
                                                                    )
                                                            ) {
                                                                window.open(
                                                                    listItem.document,
                                                                    "_blank"
                                                                );
                                                            } else {
                                                                window.open(
                                                                    process.env
                                                                        .REACT_APP_BASE_URL +
                                                                        listItem.document,
                                                                    "_blank"
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <div className='transition-all duration-200 bg-red-400  gap-  hover:scale-110 h-[240px]'>
                                                        {/* RESOURCE FILE IMAGE */}
                                                        <div
                                                            className={`${
                                                                listItem.category ===
                                                                "MoU"
                                                                    ? " h-[120px] w-full items-center justify-center flex object-contain bg-white px-[15%] py-[5%]"
                                                                    : " h-[120px] w-full items-center justify-center flex object-contain bg-white "
                                                            }`}
                                                        >
                                                            {listItem.category ===
                                                            "MoU" ? (
                                                                <img
                                                                    className=''
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_BASE_URL +
                                                                        listItem.image
                                                                        //   listItem.category === "MoU"
                                                                        //     ?  process.env.REACT_APP_BASE_URL + listItem.image
                                                                        //     : resourceImg
                                                                        // }
                                                                        // className={`${
                                                                        //   listItem.category === "MoU"
                                                                        //     ? "flex object-contain w-full h-full rounded-lg"
                                                                        //     : "flex object-contain w-full h-full rounded-lg"
                                                                        // }`
                                                                    }
                                                                    alt='resource_title'
                                                                ></img>
                                                            ) : (
                                                                <iframe
                                                                    title={
                                                                        listItem.title
                                                                    }
                                                                    src={
                                                                        listItem.document
                                                                    }
                                                                    width='100%'
                                                                    height='100%'
                                                                    frameBorder='0'
                                                                />
                                                            )}
                                                            {/* <img
                              className="flex items-center justify-center object-cover w-full h-full "
                              src={
                                process.env.REACT_APP_BASE_URL + listItem.image
                                //   listItem.category === "MoU"
                                //     ?  process.env.REACT_APP_BASE_URL + processlistItem.image
                                //     : resourceImg
                                // }
                                // className={`${
                                //   listItem.category === "MoU"
                                //     ? "flex object-contain w-full h-full rounded-lg"
                                //     : "flex object-contain w-full h-full rounded-lg"
                                // }`
                              }
                              alt="resource_title"
                            ></img> */}
                                                        </div>

                                                        {/* RESOURCE FILE TITLE AND DESCRIPTION */}
                                                        <div className='w-full overflow-hidden bg-grey rounded-b-lg shadow-md  h-[120px] b-5'>
                                                            {/* RESOURCE FILE TITLE */}
                                                            <h1 className='px-2 pt-2 text-sm font-semibold flex items-center justify-center leading-tight text-center h-[70px] '>
                                                                <TextTruncate
                                                                    line={4}
                                                                    element='span'
                                                                    truncateText='…'
                                                                    text={
                                                                        i18next.language ===
                                                                        "en"
                                                                            ? parse(
                                                                                  listItem.title
                                                                              )
                                                                            : i18next.language ===
                                                                              "amh"
                                                                            ? parse(
                                                                                  listItem.title_amh
                                                                              )
                                                                            : ""
                                                                    }
                                                                />
                                                            </h1>

                                                            {/* RESOURCE FILE DESCRIPTION */}
                                                            <div className=' h-[50px] flex  text-xs px-1 mb-10    py-1 rounded-b-lg text-center leading-tight justify-center'>
                                                                <p className='py-2 overflow-clip'>
                                                                    <TextTruncate
                                                                        line={4}
                                                                        element='span'
                                                                        truncateText='…'
                                                                        text={
                                                                            i18next.language ===
                                                                            "en"
                                                                                ? parse(
                                                                                      listItem.title
                                                                                  )
                                                                                : i18next.language ===
                                                                                  "amh"
                                                                                ? parse(
                                                                                      listItem.title_amh
                                                                                  )
                                                                                : ""
                                                                        }
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                            return acc;
                                        },
                                        []
                                    )}
                                </div>

                                <div className='gap-6 px-5 '>
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        previousClassName='border border-opacity-20 rounded-sm hover:bg-green  px-3 py-1'
                                        nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                                        pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 '
                                        containerClassName={
                                            "pagination flex justify-center text-MetallicBlue mt-10 gap-1"
                                        }
                                        activeClassName={"active bg-green"}
                                    />
                                </div>
                                {/* <div className="bg-darkorange">
                  {list.map((listItem) =>
                    ((title === "All") & (citizen === listItem.view)) |
                    ((title === listItem.category) &
                      (citizen === listItem.view)) ? (
                      <div
                        className="w-full cursor-pointer"
                        onClick={(e) => {
                          window.open(
                            process.env.REACT_APP_BASE_URL + listItem.document,
                            "_blank"
                          );
                        }}
                      >
                      
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div> */}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};
export default Sidebar;
