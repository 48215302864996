const demoFields = [
    {
        "type": "text",
        "name": "full_name",
        "mandatory": true,
        "label": "help-body-fullname"
    },
    {
        "type": "phonenumber",
        "name": "phone_number",
        "mandatory": true,
        "label": "help-phone"
    },

    {
        "type": "text",
        "name": "registration_id",
        "mandatory": false,
        "label": "help-rid"
    },
    // {
    //     "type": "radioo",
    //     "name": "issueType",
    //     "mandatory": true,
    //     "label": "help-radio" 
    // },
    {
        "type": "checkbox",
        "name": "issueType",
        "mandatory": false,
        "label": "help-body-issue4"
    },

    // {
    //     "type": "text",
    //     "name": "registration_id",
    //     "label": "help-rid",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_location",
    //     "label": "help-center",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_id",
    //     "label": "help-rid",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_location",
    //     "label": "help-phone",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_id",
    //     "label": "help-rid",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_location",
    //     "label": "help-phone",
    //     "parent": "sms not received"
    // },
    // {
    //     "type": "text",
    //     "name": "registration_id",
    //     "label": "help-rid",
    //     "parent": "lost FIN"
    // },
    // {
    //     "type": "text",
    //     "name": "uin",
    //     "label": "help-uin",
    //     "mandatory": true,
    //     "parent": "No id on mini"
    // },

]
export default demoFields;