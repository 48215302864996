import React from "react";
import { useState, useEffect, useRef } from "react";
import Iframe from "react-iframe";
import { useTranslation } from "react-i18next";
import ShowMoreText from "react-show-more-text";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { getGalleries } from "../../../../redux/selectors";

const GalleryComponentMobile = () => {
    const [Event, setEvent] = useState("Pictures");
    const list = useSelector(getGalleries);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6;
    const pageCount =
        Event == "Pictures"
            ? Math.ceil(
                  list.filter((item) => item.type === "Image").length /
                      itemsPerPage
              )
            : Event == "Videos"
            ? Math.ceil(
                  list.filter((item) => item.type === "Video").length /
                      itemsPerPage
              )
            : Math.ceil(list.length / itemsPerPage);

    const [IsOpen, setIsOpen] = useState(false);
    const [VidOpen, setVidOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");
    const [currentCaption, setCurrentCaption] = useState("");
    const [vidModal, setVidModal] = useState("");
    const imgRef = useRef(null);

    useEffect(() => {
        if (imgRef.current) {
            const imgWidth = imgRef.current.getBoundingClientRect().width;
            const modalContent = document.getElementById("modal-content");
            modalContent.style.width = `${imgWidth}px`;
        }
    }, [currentImage]);

    function toggleModal(imageUrl, caption) {
        setIsOpen(!IsOpen);
        setCurrentImage(imageUrl);
        setCurrentCaption(caption);
    }

    function toggleVidModal(vidUrl) {
        setVidOpen(!VidOpen);
        setVidModal(vidUrl);
    }

    function handlePageClick({ selected }) {
        setCurrentPage(selected);
    }

    function latest() {
        // setLatestContent(true);
        setActiveButtonIndex(4);
        setEvent("All");
        setCurrentPage(0);
        // scrollToFirstItem();
    }

    const offset = currentPage * itemsPerPage;
    const paginatedItems = list.slice(offset, offset + itemsPerPage);
    const vidItems = list.filter((item) => item.type === "Video");
    const imgItems = list.filter((item) => item.type === "Image");

    const PaginatedImgItems = imgItems
        .reverse()
        .slice(offset, offset + itemsPerPage);

    const paginatedVidItems = vidItems.slice(offset, offset + itemsPerPage);

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const { t } = useTranslation();

    const menuItems = [
        {
            name: "All",
        },
        {
            name: "Pictures",
        },
        {
            name: "Videos",
        },
    ];

    return (
        <div className='flex flex-col'>
            {IsOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleModal}
                >
                    <img
                        ref={imgRef}
                        src={process.env.REACT_APP_BASE_URL + currentImage}
                        className='h-full object-contain'
                        alt='Modal content'
                    />
                    <div
                        id='modal-content'
                        className='absolute bottom-36 px-5 bg-news-gradient'
                    >
                        <p className='text-white text-2xl z-50'>
                            {currentCaption}
                        </p>
                    </div>
                </div>
            )}
            {VidOpen && (
                <div
                    className='fixed top-0 left-0 w-full h-full py-20 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-500'
                    onClick={toggleVidModal}
                >
                    <div className='relative w-full h-80 px-2 flex hover:cursor-pointer hover:scale-105 transition-all duration-500 '>
                        <Iframe
                            url={vidModal}
                            id=''
                            className='flex w-[100%] h-full '
                            display='block'
                            position='relative'
                        />
                    </div>
                </div>
            )}
            <div className='flex bg-[#83A5B1]'>
                <div className='pl-10 py-5 text-lg font-normal  w-[100%]'>
                    <div className='relative inline-block'>
                        <input
                            onClick={() => setOpen(!open)}
                            className='block w-full py-1 text-base text-gray-500 placeholder-gray-400 bg-white appearance-none rounded-3xl  placeholder:text-center hover:cursor-pointer'
                            placeholder='Categories'
                            readOnly
                        />
                        <svg
                            className='absolute top-0 right-0 h-full w-10 text-gray-400 pointer-events-none'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                        >
                            <path
                                fillRule='evenodd'
                                d='M10 12a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42L10 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4a1 1 0 01-.71.29z'
                                clipRule='evenodd'
                            />
                        </svg>
                        {open ? (
                            <div className='absolute z-10 mt-2 w-[100%] text-center py-2 rounded-lg bg-white shadow-lg'>
                                <div className='w-full max-h-60 z-10 overflow-y-scroll'>
                                    {menuItems.map((menuItem, key) => (
                                        <div
                                            className='py-2 hover:bg-gray-100 cursor-pointer'
                                            onClick={() => {
                                                setEvent(menuItem.name);
                                                setOpen(!open);
                                            }}
                                        >
                                            {t(menuItem.name)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <button
                        className={`${
                            activeButtonIndex === 0 ? "underline" : ""
                        } text-white content-center px-6 hover:cursor-pointer decoration-green hover:underline`}
                        onClick={latest}
                    >
                        {t("Latest")}
                    </button>
                </div>
            </div>

            <div class='flex flex-col items-center backdrop-brightness-95 pt-10 my-8'>
                <div className='flex flex-col items-center'>
                    <>
                        {Event === "Pictures" ? (
                            <div className='grid grid-cols-2 gap-4  font-medium text-green text-xl px-7'>
                                {PaginatedImgItems.map((listItem) => (
                                    <div
                                        key={listItem.id}
                                        className='flex flex-col'
                                    >
                                        <div
                                            className='relative h-40 place-content-center hover:cursor-pointer hover:scale-105 transition-all duration-500'
                                            onClick={() =>
                                                toggleModal(
                                                    listItem.image_url,
                                                    listItem.caption
                                                )
                                            }
                                        >
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_BASE_URL +
                                                    listItem.image_url
                                                }
                                                className='w-[100%] h-[100%] object-cover'
                                                alt='gallery img'
                                            />
                                        </div>

                                        <div className='px-4 py-5'>
                                            <p className='text-MetallicBlue'>
                                                <ShowMoreText
                                                    lines={1}
                                                    more='Show more'
                                                    less='Show less'
                                                    className='content-css -mr-4 ml-[1px] leading-tight'
                                                    anchorClass='show-more-less-clickable'
                                                    expanded={false}
                                                    width={300}
                                                    truncatedEndingComponent={
                                                        "..."
                                                    }
                                                >
                                                    {listItem.caption}
                                                </ShowMoreText>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : Event === "All" ? (
                            <div className='grid grid-cols-2 gap-4  font-medium text-green text-xl px-7'>
                                {paginatedItems.map((listItem) =>
                                    listItem.image_url ? (
                                        <div
                                            key={listItem.id}
                                            className='flex flex-col'
                                        >
                                            <div
                                                className='relative h-40 place-content-center hover:cursor-pointer hover:scale-105 transition-all duration-500'
                                                onClick={() =>
                                                    toggleModal(
                                                        listItem.image_url,
                                                        listItem.caption
                                                    )
                                                }
                                            >
                                                <img
                                                    src={
                                                        process.env
                                                            .REACT_APP_BASE_URL +
                                                        listItem.image_url
                                                    }
                                                    className='w-[100%] h-[100%] object-cover'
                                                    alt='gallery img'
                                                />
                                            </div>

                                            <div className='px-4 py-5'>
                                                <p className='text-MetallicBlue'>
                                                    <ShowMoreText
                                                        lines={1}
                                                        more='Show more'
                                                        less='Show less'
                                                        className='text-justify content-css -mr-4 ml-[1px] tracking-tighter'
                                                        anchorClass='show-more-less-clickable'
                                                        expanded={false}
                                                        width={300}
                                                        truncatedEndingComponent={
                                                            "..."
                                                        }
                                                    >
                                                        {listItem.caption}
                                                    </ShowMoreText>
                                                </p>
                                            </div>
                                        </div>
                                    ) : listItem.video_url ? (
                                        <div
                                            key={listItem.id}
                                            className='flex flex-col'
                                            onClick={() =>
                                                toggleVidModal(
                                                    listItem.video_url
                                                )
                                            }
                                        >
                                            <div className='relative h-40 flex hover:cursor-pointer transition-all duration-500'>
                                                <Iframe
                                                    url={listItem.video_url}
                                                    id=''
                                                    className='flex w-[100%] h-full -z-20'
                                                    display='block'
                                                    position='relative'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        ) : Event === "Videos" ? (
                            <div className='grid grid-cols-2 gap-4  font-medium text-green text-xl px-7'>
                                {paginatedVidItems.map((listItem) => (
                                    <div
                                        key={listItem.id}
                                        className='flex flex-col'
                                        onClick={() =>
                                            toggleVidModal(listItem.video_url)
                                        }
                                    >
                                        <div className='relative h-40 flex hover:cursor-pointer transition-all duration-500'>
                                            <Iframe
                                                url={listItem.video_url}
                                                id=''
                                                className='flex w-[100%] h-full -z-20'
                                                display='block'
                                                position='relative'
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                    <div className='w-full gap-6 bg-white'>
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            previousClassName='border border-opacity-20 rounded-sm hover:bg-green  px-3 py-1'
                            nextClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1'
                            pageClassName='border border-opacity-20 rounded-sm hover:bg-green px-3 py-1 '
                            containerClassName={
                                "pagination flex justify-center text-MetallicBlue mt-10 gap-1"
                            }
                            activeClassName={"active bg-green"}
                        />
                    </div>
                </div>
                {/* <div className="w-full flex justify-center bg-white pt-10 pb-5">
         
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          /> */}
                {/* <div className="flex flex-row  font-semibold text-MetallicBlue space-x-2">
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={(pageNumber) => clickHandler}
            >
              First
            </button>
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={() => clickHandler}
            >
              1
            </button>
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={() => clickHandler}
            >
              2
            </button>
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={() => clickHandler}
            >
              3
            </button>
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={() => clickHandler}
            >
              4
            </button>
            <button
              className="border border-opacity-20 rounded-sm hover:bg-green px-3 py-1"
              onClick={() => clickHandler}
            >
              Last
            </button>
          </div> 
        </div>*/}
            </div>
        </div>
    );
};

export default GalleryComponentMobile;
