import React from "react";
import TextTruncate from "react-text-truncate";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { Animate } from "../../../../hooks/animations";
import webinar from "../../../../assets/webinar.jpg";
import tender from "../../../../assets/tender.jpg";
import press from "../../../../assets/press.jpg";
import { useSelector } from "react-redux";
import { getNews } from "../../../../redux/selectors";

const NewsMobile = () => {
    const list = useSelector(getNews);
    const navigate = useNavigate();

    return (
        <div className='flex bg-grey'>
            <div className='flex flex-col justify-center mx-6 mb-7 mt-7 place-items-center '>
                <div className='flex-col '>
                    {/******************************************  Big News ****************************************************/}
                    <Animate.FadeIn>
                        <div
                            onClick={() =>
                                navigate(
                                    `/updates/${
                                        list[0].type
                                    }/${list[0].title.replace(/ /g, "-")}`,
                                    { state: { news: list[0] } }
                                )
                            }
                            className='relative flex flex-col mt-5 text-left rounded-sm bg-darkorange'
                        >
                            <img
                                className='w-[100] object-cover h-full align-middle rounded-sm shadow-md'
                                src={
                                    process.env.REACT_APP_BASE_URL +
                                        list[0].image !==
                                    ""
                                        ? process.env.REACT_APP_BASE_URL +
                                          list[0].image
                                        : list[0].type === "Webinar"
                                        ? webinar
                                        : list[0].type === "Press"
                                        ? press
                                        : list[0].type === "Tender"
                                        ? tender
                                        : i18next.language === "amh"
                                        ? process.env.REACT_APP_BASE_URL +
                                          list[0].image_amh
                                        : process.env.REACT_APP_BASE_URL +
                                          list[0].image
                                }
                                alt={list[0].image}
                            />

                            <div className='absolute flex w-[100%] bottom-0 left-0 right-0 px-4 py-4 text-base bg-news-gradient'>
                                {" "}
                                <div class=' h-8  bg-[#21cfac] w-1 mr-2 my-1'></div>
                                <h1 className='w-full leading-tight text-white '>
                                    {" "}
                                    <TextTruncate
                                        line={2}
                                        element='span'
                                        truncateText='…'
                                        text={
                                            i18next.language === "en"
                                                ? list[0].title
                                                : i18next.language === "amh"
                                                ? list[0].title_amh
                                                : ""
                                        }
                                    />
                                </h1>
                            </div>
                        </div>
                    </Animate.FadeIn>

                    {/****************************************** Small News wrapper ****************************************************/}
                    <div className='grid grid-cols-2 gap-5'>
                        {/****************************************** Left -Small News ****************************************************/}
                        <Animate.FadeIn>
                            <div
                                onClick={() =>
                                    navigate(
                                        `/updates/${
                                            list[1].type
                                        }/${list[1].title.replace(/ /g, "-")}`,
                                        { state: { news: list[1] } }
                                    )
                                }
                                className='relative flex flex-col mt-5 text-left '
                            >
                                <img
                                    className='object-cover w-[100%]  h-24 shadow-md rounded-sm justify-start flex'
                                    src={
                                        process.env.REACT_APP_BASE_URL +
                                            list[1].image !==
                                        ""
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[1].image
                                            : list[1].type === "Webinar"
                                            ? webinar
                                            : list[1].type === "Press"
                                            ? press
                                            : list[1].type === "Tender"
                                            ? tender
                                            : i18next.language === "amh"
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[1].image_amh
                                            : process.env.REACT_APP_BASE_URL +
                                              list[1].image
                                    }
                                    alt={list[1].image}
                                />

                                <div className='flex absolute w-[100%] bottom-0 left-0 right-0 px-2 py-2 text-base bg-news-gradient  '>
                                    {" "}
                                    <div class=' h-5  bg-[#21cfac] w-1 mr-2 my-1'></div>
                                    <h1 className='w-full text-xs leading-tight tracking-tighter text-white overflow-clip '>
                                        {" "}
                                        <TextTruncate
                                            line={2}
                                            element='span'
                                            truncateText='…'
                                            text={
                                                i18next.language === "en"
                                                    ? list[1].title
                                                    : i18next.language === "amh"
                                                    ? list[1].title_amh
                                                    : ""
                                            }
                                        />
                                    </h1>
                                </div>
                            </div>
                        </Animate.FadeIn>

                        {/******************************************  Right - Small News ****************************************************/}
                        <Animate.FadeIn>
                            <div
                                onClick={() =>
                                    navigate(
                                        `/updates/${
                                            list[2].type
                                        }/${list[2].title.replace(/ /g, "-")}`,
                                        { state: { news: list[2] } }
                                    )
                                }
                                className='relative flex flex-col mt-5 text-left w-[100%] '
                            >
                                <img
                                    className='object-cover w-[100%]  h-24 shadow-md rounded-sm justify-end flex'
                                    src={
                                        process.env.REACT_APP_BASE_URL +
                                            list[2].image !==
                                        ""
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[2].image
                                            : list[2].type === "Webinar"
                                            ? webinar
                                            : list[2].type === "Press"
                                            ? press
                                            : list[2].type === "Tender"
                                            ? tender
                                            : i18next.language === "amh"
                                            ? process.env.REACT_APP_BASE_URL +
                                              list[2].image_amh
                                            : process.env.REACT_APP_BASE_URL +
                                              list[2].image
                                    }
                                    alt={list[2].image}
                                />

                                <div className='flex absolute w-[100%] bottom-0 left-0 right-0 px-2 py-2 text-base bg-news-gradient  '>
                                    {" "}
                                    <div class=' h-5  bg-[#21cfac] w-1 mr-2 my-1'></div>
                                    <h1 className='w-full text-xs leading-tight tracking-tighter text-white '>
                                        {" "}
                                        <TextTruncate
                                            line={2}
                                            element='span'
                                            truncateText='…'
                                            text={
                                                i18next.language === "en"
                                                    ? list[2].title
                                                    : i18next.language === "amh"
                                                    ? list[2].title_amh
                                                    : ""
                                            }
                                        />
                                    </h1>
                                </div>
                            </div>
                        </Animate.FadeIn>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NewsMobile;
