// import { strategicPartners } from "./strategicPartners";
import { FaAngleDown } from "react-icons/fa6";
import CountUp from "react-countup";
import { partnersSmallConfig, partnersLargeConfig } from "./partnersConfig";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

import { Link } from "react-router-dom";

const Body = () => {
    const [display, setDisplay] = useState(partnersSmallConfig);
    const handleLoadMore = () => {
        setDisplay(
            display === partnersLargeConfig
                ? partnersSmallConfig
                : partnersLargeConfig
        );
    };
    const { t } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className=''>
            {/* <p className='text-[#225D71] text-5xl font-[400]'>
                NIDP Strategic Partners
            </p>
            <div>
                <p className='text-justify'>
                    The Faydad IDMS is the central Foundationsl ID Managment
                    system that integrates with many other external
                    Identification System(ABIS) alternatives, and card printing
                    services, The Fayda IDMS is the central Foundational ID
                    Managment system that integrates with many other external
                    Identification System(ABIS) alternatives, and card printing
                    services
                </p>
                <div>
                    <div class='flex justify-center flex-wrap gap-5 md:gap-x-32 md:gap-y-5 pt-12 pb-5'>
                        {strategicPartners.map((partner) => (
                            <div className='w-fit sm:w-1/3 md:w-1/3 lg:w-1/6 p-10 border-2 border-[gray] rounded-2xl space-y-5'>
                                <div className='rounded-full h-[100px] w-[100px] border-[gray] mx-auto overflow-clip border flex'>
                                    <img
                                        src={partner.image}
                                        className='rounded-full object-scale-down object-center'
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            <div className='px-10 md:px-0 xl:px-0 xl:pl-28 xl:pr-40 space-y-5 py-20'>
                <div className='px-5 md:px-0 xl:px-0 space-y-10'>
                    <div
                        class={` w-full gap-5 ${
                            display === partnersLargeConfig
                                ? "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 md:grid-cols-3 "
                                : "flex flex-col md:flex-row md:flex-wrap justify-center"
                        } md:gap-x-8 lg:gap-x-14 md:gap-y-10 md:px-6 pt-12 pb-10 w-full mx-auto`}
                    >
                        {display.map((partner, index) => (
                            <div
                                key={index}
                                className={`w-full px-10 py-5 border-2 border-white rounded-2xl space-y-5 bg-[#376175] ring-2 ring-[#376175] ring-offset-2 text-white ${
                                    display === partnersLargeConfig
                                        ? "xl:grid-cols-4"
                                        : "justify-center md:max-w-[25%]"
                                } `}
                            >
                                <div className='rounded-full h-[100px] w-[100px] mx-auto overflow-clip border border-white flex bg-white'>
                                    <img
                                        src={partner.image}
                                        className='rounded-full object-scale-down object-center'
                                    />
                                </div>
                                <div className='px-2 text-center w-full flex items-center gap-3 justify-center  border border-white rounded-full'>
                                    <CountUp
                                        start={0}
                                        end={partner.locations}
                                        className='text-2xl font-bold'
                                    />{" "}
                                    <p> Locations</p>
                                </div>
                                <p className='text-xl font-[500] text-center'>
                                    {partner.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={() => handleLoadMore()}
                        className='flex flex-col mx-auto w-fit items-center gap-1 font-semibold'
                    >
                        <div className='border-2 border-[#376175] px-4 py-1 rounded-full w-fit'>
                            {display === partnersSmallConfig
                                ? "Show More"
                                : "Show Less"}
                        </div>
                        <div className='animate-bounce w-fit text-[#376175]'>
                            {display === partnersSmallConfig ? (
                                <FaAngleDown />
                            ) : (
                                <FaAngleUp />
                            )}{" "}
                        </div>
                    </button>
                </div>{" "}
            </div>
            <div className='text-[#235D71] bg-qrscanner-hero bg-right flex flex-col xl:flex-row items-center justify-center gap-10 md:px-10 xl:px-32 xl:pl-28 xl:pr-40 space-y-5 py-20'>
                <div className='md:max-w-[60%]'>
                    {isDesktopOrLaptop ? (
                        <div className='flex items-center'>
                            <div className='text-[#235D71] text-3xl  space-y-5'>
                                <p className='relative mb-5 justify-start text-6xl font-bold '>
                                    {t("partner-reg-title-1")}
                                </p>
                                <p className='relative flex text-xl font-light  leading-tight text-justify'>
                                    {t("partner-reg-text-1")}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-col xl:flex-row items-center px-5 gap-5'>
                            <p className='relative mb-5 justify-start text-6xl font-bold '>
                                {t("partner-reg-title-1")}
                            </p>
                            <div className='relative flex text-xl font-light  leading-tight text-justify'>
                                <p>{t("partner-reg-text-1")}</p>
                            </div>
                        </div>
                    )}
                </div>
                <Link
                    to='/relying-party'
                    className='px-5 md:px-14 py-1.5 text-xl gap-2 flex mt-1 items-center text-white bg-MetallicBlue rounded-3xl hover:bg-[#083247] min-w-fit'
                >
                    {t("partners-join-us")} <FaArrowRight />{" "}
                </Link>
            </div>
        </div>
    );
};

export default Body;
