import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import SmallFooter from "../../components/footer/smallFooter";
import Home from "./pages/page";
import TopBar from "../../components/navbar/navbar";
import SideBar from "../../components/sidebar/sidebar";
const ResendHelp = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='min-h-fit'>
            {isDesktopOrLaptop ? <TopBar /> : <SideBar />}
            <Home className='' />
            <SmallFooter />
        </div>
    );
};

export default ResendHelp;
