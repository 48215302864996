import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as IoIcons5 from "react-icons/io5";

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,


  },
  {
    title: 'About Us',
    path: '/about',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    // subNav: [
    //   {
    //     title: 'Vacancy',
    //     path: '/career',
    //     icon: <IoIcons.IoIosPaper />
    //   },
    //   {
    //     title: 'Partners',
    //     path: '/about',
    //     icon: <IoIcons.IoIosPaper />
    //   }
    // ]
  },
  {
    title: 'Services',
    path: '/services',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      {
        title: 'Credentials',
        path: '/credentials',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        type: "internal",

      },
      {
        title: 'Enrollment',
        path: '/enrollment',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav',
        type: "internal",

      },
      {
        title: "authentication",
        path: "/authentication",
        icon: <IoIcons5.IoPrintOutline />,
        cName: 'sub-nav',
        type: "internal",
      },
      {
        title: "card-print",
        path: "card-order.fayda.et/",
        icon: <IoIcons5.IoPrintOutline />,
        cName: 'sub-nav',
        type: "external",
      },
    ]

  },
  {
    title: 'Resources',
    path: '/resources',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [

      {
        title: 'MOU',
        path: '/mou',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav', type: "internal",

      },
      {
        title: 'Decks',
        path: '/decks',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Policies and Strategies',
        path: '/policies',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Manuals',
        path: '/manuals',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",
      },
      {
        title: 'Publications',
        path: '/documents',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Specifications',
        path: '/nidspecifications',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Law',
        path: '/law',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'World Bank',
        path: '/worldbank',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      }
    ]
  },
  {
    title: 'Updates',
    path: '/news',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'News',
        path: '/news',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Events',
        path: '/events',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Media',
        path: '/media',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Vacancies',
        path: '/career',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Tender',
        path: '/tender',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      },
      {
        title: 'Podcasts',
        path: '/podcast',
        icon: <IoIcons.IoIosPaper />,
        type: "internal",

      }
    ]
  },
  {
    title: 'FAQ',
    path: '/faq',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

  },
  {
    title: 'Contact Us',
    path: '/contact',
    icon: <IoIcons.IoMdPeople />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

  },


];